/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import ToolTipButton from 'Component/ToolTipButton';
import { FieldFile as SourceFieldFileComponent } from 'JaidahComponent/FieldFile/FieldFile.component';
/** @namespace Geely/Component/FieldFile/Component */
export class FieldFile extends SourceFieldFileComponent {
    renderToolTipButton() {
        const { hasTooltip, tooltipContent, attr: { id = {} } = {} } = this.props;

        if (hasTooltip) {
            return <ToolTipButton hasCloseBtn={ false } tooltipContent={ tooltipContent } id={ id } />;
        }

        return null;
    }
}

export default FieldFile;
