/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
export const renderMinToolTip = () => null;

export const renderMaxToolTip = () => null;

export const getPriceFilterLabel = (from, to, minPrice, maxPrice, formatPrice) => {
    if (from === minPrice) {
        return __('Up to %s', formatPrice(to));
    }

    if (to === maxPrice) {
        return __('From %s', formatPrice(from));
    }

    return __('From %s to %s', formatPrice(from), formatPrice(to));
};

export const renderPriceRange = (props) => {
    const {
        minPrice,
        maxPrice,
        minPriceInputValue,
        maxPriceInputValue,
        getPriceFormat
    } = props;

    if (minPriceInputValue === minPrice && maxPriceInputValue === maxPrice) {
        return null;
    }

    return (
        <div block="PriceSlider" elem="PriceRange">
            <span block="PriceSlider" elem="PriceRangeText">
                { __('Selected price: ') }
            </span>
            { getPriceFilterLabel(minPriceInputValue, maxPriceInputValue, minPrice, maxPrice, getPriceFormat) }
        </div>
    );
};

export const render = (args, callback, instance) => {
    const { props } = instance;

    return (
        <div block="PriceSlider">
            <div block="PriceSlider" elem="Wrapper">
                { renderPriceRange(props) }
                { instance.renderProgressBar() }
                { instance.renderPriceRange() }
            </div>
        </div>
    );
};

export default {
    'PriceSlider/Component/PriceSlider/Component': {
        'member-function': {
            renderMaxToolTip,
            renderMinToolTip,
            render
        }
    }
};
