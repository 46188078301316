import {
    MyAccountLoginPopupComponent as JaidahMyAccountLoginPopupComponent
} from 'JaidahComponent/MyAccountLoginPopup/MyAccountLoginPopup.component';

import './MyAccountLoginPopup.geely.style';

/** @namespace Geely/Component/MyAccountLoginPopup/Component */
export class MyAccountLoginPopupComponent extends JaidahMyAccountLoginPopupComponent {
    // TODO implement logic
}

export default MyAccountLoginPopupComponent;
