import {
    AUTOMATIC_RELATED,
    CROSS_SELL,
    getInitialState as jaidahGetInitialState,
    INSURANCE,
    LinkedProductsReducer as jaidahLinkedProductsReducer,
    RELATED,
    UPSELL
} from 'JaidahStore/LinkedProducts/LinkedProducts.reducer';
import { UPDATE_LINKED_PRODUCTS } from 'Store/LinkedProducts/LinkedProducts.action';
import { LINKED_PRODUCTS } from 'Store/LinkedProducts/LinkedProducts.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';

import {
    ACCESSORIES,
    HYUNDAI,
    SERVICE_PACKAGES
} from './LinkedProducts.dispatcher';

export {
    UPSELL,
    RELATED,
    CROSS_SELL,
    AUTOMATIC_RELATED,
    INSURANCE
};

/** @namespace Geely/Store/LinkedProducts/Reducer/getInitialState */
export const getInitialState = () => {
    const localState = BrowserDatabase.getItem(LINKED_PRODUCTS);

    if (localState) {
        return {
            linkedProducts: localState,
            isLoading: false
        };
    }

    const initialState = jaidahGetInitialState();
    const { linkedProducts } = initialState;

    return {
        ...initialState,
        linkedProducts: {
            ...linkedProducts,
            [AUTOMATIC_RELATED]: {
                items: {
                    [ACCESSORIES]: {},
                    [SERVICE_PACKAGES]: {},
                    [HYUNDAI]: {}
                },
                total_count: 3
            }
        }
    };
};

/** @namespace Geely/Store/LinkedProducts/Reducer/LinkedProductsReducer */
export const LinkedProductsReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_LINKED_PRODUCTS:
        const {
            linkedProducts: {
                [UPSELL]: upsell,
                [RELATED]: related,
                [CROSS_SELL]: crosssell,
                [AUTOMATIC_RELATED]: automatic_related,
                [INSURANCE]: insurance,
                updateCrossSell = false
            }
        } = action;

        const {
            linkedProducts: {
                [CROSS_SELL]: prevCrossSell
            }
        } = state;

        if (updateCrossSell) {
            return {
                ...state,
                linkedProducts: {
                    [UPSELL]: upsell,
                    [RELATED]: related,
                    [CROSS_SELL]: crosssell,
                    [AUTOMATIC_RELATED]: automatic_related,
                    [INSURANCE]: insurance
                },
                isLoading: false
            };
        }

        return {
            ...state,
            linkedProducts: {
                [UPSELL]: upsell,
                [RELATED]: related,
                [CROSS_SELL]: {
                    ...prevCrossSell,
                    ...related,
                    items: Object.values({
                        ...prevCrossSell.items,
                        ...crosssell.items
                    })
                },
                [AUTOMATIC_RELATED]: automatic_related,
                [INSURANCE]: insurance
            },
            isLoading: false
        };
    default:
        return jaidahLinkedProductsReducer(state, action);
    }
};

export default LinkedProductsReducer;
