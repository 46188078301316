import ClickOutside from 'Component/ClickOutside';
import {
    PopupComponent as JaidahPopupComponent
} from 'JaidahComponent/Popup/Popup.component';
import { noopFn } from 'Util/Common';

import './Popup.geely.style';

/** @namespace Geely/Component/Popup/Component */
export class PopupComponent extends JaidahPopupComponent {
    renderContent() {
        const {
            children,
            contentMix,
            hasCloseBtn,
            isNotClickOutside
        } = this.props;
        const isVisible = this.getIsVisible();
        const handleClick = isNotClickOutside ? noopFn : this.handleClickOutside;

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ handleClick }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    { this.renderHeader() }
                    <div
                      block="Popup"
                      elem="ChildrenWrapper"
                      mods={ { hasCloseButton: hasCloseBtn } }
                    >
                        { this.renderNotifications() }
                        { children }
                    </div>
                    { hasCloseBtn && this.renderBottomCloseBtn() }
                </div>
            </ClickOutside>
        );
    }

    renderHeader() {
        const {
            title,
            hasCloseBtn,
            isTooltipMobile
        } = this.props;

        if (isTooltipMobile) {
            return null;
        }

        return (
            <header
              block="Popup"
              elem="Header"
              mods={ { hasTitle: !!title } }
            >
                <div block="Popup" elem="HeaderWrapper">
                    { this.renderTitle() }
                    { !hasCloseBtn && this.renderCloseButton() }
                </div>
            </header>
        );
    }

    renderBottomCloseBtn() {
        return (
            <div block="Popup" elem="BottomCloseBtn">
                <button
                  block="Button"
                  mix={ { block: 'Popup', elem: 'Button' } }
                  onClick={ this.hidePopupAndGoBack }
                >
                    { __('Close') }
                </button>
            </div>
        );
    }
}

export default PopupComponent;
