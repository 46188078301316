/* eslint-disable react/jsx-no-bind */
import ExpandableContent from 'Component/ExpandableContent';
import { STOCK_TYPE } from 'Component/Product/Stock.config';
import TextPlaceholder from 'Component/TextPlaceholder';

const renderSelectButtonPlugin = (
    option = {},
    attribute_code,
    showOutOfStock = false,
    instance
) => {
    const {
        label = '',
        value = ''
    } = option;

    const {
        updateConfigurableVariant,
        parameters = {},
        areDetailsLoaded,
        variants,
        parentInStock
    } = instance.props;

    if (!areDetailsLoaded) {
        return <div block="ProductStepPlaceholder"><TextPlaceholder length="medium" /></div>;
    }

    const isInStock = variants.some(
        ({
            attributes: {
                [attribute_code]: { attribute_value }
            },
            stock_status,
            stock_item: {
                in_stock = false
            }
        }) => attribute_value === value && (stock_status === STOCK_TYPE.IN_STOCK || in_stock)
    );

    const renderLabel = showOutOfStock && (!isInStock || !parentInStock);

    return (
        <button
          key={ value || attribute_code }
          onClick={ () => updateConfigurableVariant(attribute_code, value) }
          block="ProductStepsTab"
          elem="Select"
          mods={ { isActive: parameters?.[attribute_code] === value } }
          mix={ { block: 'Button', mods: { isHollow: true } } }
        >
            { label }
            { renderLabel && (
                <div block="ProductStepsTab" elem="OutOfStockLabel">
                    { __('Sold out') }
                </div>
            ) }
        </button>
    );
};

const renderSelectType = (args, callback, instance) => {
    const [attribute, , isStarting, showOutOfStock] = args;

    const {
        attribute_options = {},
        attribute_code = '',
        attribute_label = '',
        values = []
    } = attribute || {};

    if (showOutOfStock && isStarting) {
        return (
            <div block="ProductStepsTab" elem="SelectWrapper">
                { values.map(
                    ({ value_index }) => renderSelectButtonPlugin(
                        attribute_options[value_index],
                        attribute_code,
                        showOutOfStock,
                        instance
                    )
                ) }
            </div>
        );
    }

    if (showOutOfStock) {
        return (
            <ExpandableContent
              key={ attribute_code }
              heading={ attribute_label }
              mix={ { block: 'ProductSteps', elem: 'Section' } }
              isContentExpanded
              isArrow
            >
                <div block="ProductStepsTab" elem="SelectWrapper">
                    { values.map(
                        ({ value_index }) => renderSelectButtonPlugin(
                            attribute_options[value_index],
                            attribute_code,
                            showOutOfStock,
                            instance
                        )
                    ) }
                </div>
            </ExpandableContent>
        );
    }

    return callback(...args);
};

export default {
    'Scandipwa/Component/ProductSteps/Component': {
        'member-function': {
            renderSelectType: {
                implementation: renderSelectType,
                position: 110
            }
        }
    }
};
