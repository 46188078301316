import ChevronIcon from 'Component/ChevronIcon';
import {
    BOTTOM,
    TOP
} from 'Component/ChevronIcon/ChevronIcon.config';
import {
    ExpandableContentComponent as JaidahExpandableContentComponent
} from 'JaidahComponent/ExpandableContent/ExpandableContent.component';

/** @namespace Geely/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends JaidahExpandableContentComponent {
    // Inverse arrow direction
    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const {
            isArrow,
            device: { isMobile },
            renderIcon
        } = this.props;

        if (!(isMobile || renderIcon)) {
            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? BOTTOM : TOP } />;
        }

        return this.renderTogglePlusMinus();
    }
}

export default ExpandableContentComponent;
