/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';

import { MY_VEHICLES_URL } from 'Component/Header/Header.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import { showPopup } from 'Store/Popup/Popup.action';
import { getCartId as getGuestQuoteId } from 'Util/Cart';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { getErrorMessage } from 'Util/Request';
import { appendWithStoreCode, getQueryParam } from 'Util/Url';

import { SMS_CODE_POPUP } from '../SmsCodePopup/SmsCodePopup.config';
import MyAccountSignIn from './MyAccountSignIn.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    is_sms_enabled: state.ConfigReducer.sms_signup_verification_enable
});

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    getOTPSignInValid: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getOTPSignInValid(options, dispatch)
    ),
    otpSignIn: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.otpSignIn(options, dispatch)
    ),
    showSmsPopup:
        (phone, onSuccess, onError = () => {}, onResend = () => {}, isSignIn = false) => dispatch(
            showPopup(SMS_CODE_POPUP, {
                onSuccess, onError, phone, onResend, isSignIn
            })
        ),
    createGuestEmptyCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.createGuestEmptyCart(dispatch)
    )
});
/** @namespace Scandipwa/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    static propTypes = {
        ...SourceMyAccountSignInContainer.propTypes,
        isOTPSignIn: PropTypes.bool,
        is_sms_enabled: PropTypes.bool,
        getOTPSignInValid: PropTypes.func.isRequired,
        otpSignIn: PropTypes.func.isRequired,
        showSmsPopup: PropTypes.func.isRequired,
        createGuestEmptyCart: PropTypes.func.isRequired

    };

    static defaultProps = {
        ...SourceMyAccountSignInContainer.defaultProps,
        isOTPSignIn: false,
        is_sms_enabled: false
    };

    onSuccessSubmitTimeOutRef = createRef();

    /* JAID-257 Overriden to add back to previous page functionality */
    async onSignInSuccess(form, fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState
        } = this.props;

        const { state } = history?.location;

        setLoadingState(true);
        const fieldPairs = transformToNameValuePair(fields);

        try {
            await signIn(fieldPairs);
            onSignIn();
            if (state?.isMyVehicle) {
                history.push(appendWithStoreCode(state.prevLocation));
            }
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }

        setLoadingState(false);
    }

    async onOTPSignInSuccess(form, fields) {
        const {
            getOTPSignInValid,
            showNotification,
            setLoadingState,
            showSmsPopup,
            createGuestEmptyCart
        } = this.props;

        setLoadingState(true);
        const fieldPairs = transformToNameValuePair(fields);

        const { phone } = fieldPairs;

        try {
            await createGuestEmptyCart();
            const options = {
                ...fieldPairs,
                quote_id: getGuestQuoteId()
            };
            const isOTPSignInValid = await getOTPSignInValid(options);

            if (isOTPSignInValid.generateLoginOTPCode) {
                showSmsPopup(
                    phone,
                    this.onSuccessfulOTPVerification.bind(this, options),
                    () => {},
                    async () => {
                        await getOTPSignInValid(options);
                    },
                    true
                );
            }
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        } finally {
            setLoadingState(false);
        }
    }

    async onSuccessfulOTPVerification(fieldPairs, otp_code, onSuccess, stopLoading, proceedDelay) {
        const {
            otpSignIn,
            showNotification,
            onSignIn,
            setLoadingState
        } = this.props;

        const { state } = history?.location;

        try {
            const [
                // eslint-disable-next-line no-unused-vars
                _,
                showPasswordResetOnLogin = false,
                passwordResetToken = '',
                showEmailField = false,
                customerId = 0
            ] = await otpSignIn({
                ...fieldPairs, otp_code, quote_id: getGuestQuoteId(), proceedDelay
            });

            onSuccess();
            stopLoading();
            onSignIn();
            this.onSuccessSubmitTimeOutRef.current = setTimeout(() => {
                const isQrSignin = getQueryParam('quicklogin', location || window.location) === 'qr';

                if (!showPasswordResetOnLogin) {
                    if (state?.isMyVehicle) {
                        history.push(appendWithStoreCode(state.prevLocation));
                    } else if (isQrSignin) {
                        history.push(appendWithStoreCode(MY_VEHICLES_URL));
                    }
                }

                if (showPasswordResetOnLogin && passwordResetToken) {
                    const changePasswordFullUrl = appendWithStoreCode(`/customer/account/createPassword/?token=${passwordResetToken}&id=${customerId}${showEmailField ? '&email=true' : ''}`);
                    if (isQrSignin) {
                        history.push(
                            changePasswordFullUrl,
                            { pathToRedirect: MY_VEHICLES_URL }
                        );
                    } else {
                        history.push(changePasswordFullUrl);
                    }
                }

                setLoadingState(false);
            }, proceedDelay);
        } catch (error) {
            showNotification('error', getErrorMessage(error));
            setLoadingState(false);
            stopLoading();
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        onOTPSignInSuccess: this.onOTPSignInSuccess.bind(this)
    };

    containerProps() {
        const { isOTPSignIn, is_sms_enabled } = this.props;

        return {
            ...super.containerProps(),
            isOTPSignIn,
            is_sms_enabled
        };
    }

    componentWillUnmount() {
        const { current = null } = this.onSuccessSubmitTimeOutRef;

        clearTimeout(current);
    }

    render() {
        return (
            <MyAccountSignIn
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
