/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import CarouselScrollArrow from 'Component/CarouselScrollArrow';
import CarouselScrollItem from 'Component/CarouselScrollItem';
import { CarouselScroll as SourceCarouselScroll } from 'SourceComponent/CarouselScroll/CarouselScroll.component';

import { CAROUSEL_SLIDE_COUNT } from './CarouselScroll.config';

import './CarouselScroll.geely.style.scss';

/** @namespace Geely/Component/CarouselScroll/Component */
export class CarouselScroll extends SourceCarouselScroll {
    state = {
        ...this.state,
        leftItems: 0,
        rightItems: 0
    };

    updateArrowItems() {
        const { children: { length } } = this.props;
        const { firstCarouselItemId } = this.state;

        if (length && length > CAROUSEL_SLIDE_COUNT) {
            const rightItems = length - (firstCarouselItemId + CAROUSEL_SLIDE_COUNT);

            this.setState({
                leftItems: firstCarouselItemId,
                rightItems
            });
        } else {
            this.setState({
                leftItems: 0,
                rightItems: 0
            });
        }
    }

    renderContentItem(child, key) {
        const {
            activeItemId, firstCarouselItemId, leftItems, rightItems
        } = this.state;
        const isFirst = firstCarouselItemId === key;
        const isLast = key === firstCarouselItemId + CAROUSEL_SLIDE_COUNT - 1;

        if (isFirst || isLast) {
            this.updateArrowItems();
        }
        const slideCount = isFirst ? leftItems : rightItems;

        return (
            <CarouselScrollItem
              key={ key }
              position={ key }
              onClick={ this.handleChange }
              itemRef={ this.itemRef }
              isActive={ key === activeItemId }
              isFirstOrLast={ isFirst || isLast }
              slideCount={ slideCount }
            >
                { child }
            </CarouselScrollItem>
        );
    }

    renderArrow(isNextArrow = false) {
        const { showArrow, children: { length: childrenLength }, showedItemCount } = this.props;
        const { firstCarouselItemId } = this.state;

        if (!showArrow || childrenLength <= showedItemCount) {
            return null;
        }

        // render hidden arrow to avoid carousel jumping on error hide/appear
        const isInvisible = (!isNextArrow && firstCarouselItemId === 0)
            || (isNextArrow && firstCarouselItemId >= this.getMaxFirstItemId());

        return (
            <CarouselScrollArrow
              isNextArrow={ isNextArrow }
              onClick={ this.handleArrowClick }
              isInvisible={ isInvisible }
            />
        );
    }
}

export default CarouselScroll;
