import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductGalleryContainer as SourceProductGalleryContainer
} from 'SourceComponent/ProductGallery/ProductGallery.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Geely/Component/ProductGallery/Container */
export class ProductGalleryContainer extends SourceProductGalleryContainer {
    containerFunctions = {
        ...this.containerFunctions,
        toggleImageZoomPopupActive: this.toggleImageZoomPopupActive.bind(this)
    };

    toggleImageZoomPopupActive() {
        const { isMobile } = this.props;
        const { isImageZoomPopupActive } = this.setState;

        if (isMobile) {
            return;
        }

        this.setState({ isImageZoomPopupActive: !isImageZoomPopupActive });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryContainer);
