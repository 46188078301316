import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps,
    mapStateToProps,
    RelatedProductTotalsContainer as JaidahRelatedProductTotalsContainer
} from 'JaidahComponent/RelatedProductTotals/RelatedProductTotals.container';
import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import ProductOptionsReducer from 'Store/ProductOptions/ProductOptions.reducer';
import { withReducers } from 'Util/DynamicReducer';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Geely/Component/RelatedProductTotals/Container */
export class RelatedProductTotalsContainer extends JaidahRelatedProductTotalsContainer {
    containerProps() {
        const { vehiclePrice, productType } = this.props;

        return {
            ...super.containerProps(),
            vehiclePrice,
            productType
        };
    }
}

export default
withRouter(
    withReducers(
        { LinkedProductsReducer, ProductOptionsReducer }
    )(connect(mapStateToProps, mapDispatchToProps)(RelatedProductTotalsContainer))
);
