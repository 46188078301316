import ToolTipPopup from 'Component/ToolTipPopup';
import {
    ToolTipButtonComponent as JaidahToolTipButtonComponent
} from 'JaidahComponent/ToolTipButton/ToolTipButton.component';
import { ReactComponent as InfoIcon } from 'Style/icons/icon-info-active.svg';
import { noopFn } from 'Util/Common';

import { TOOLTIP_POPUP_PREFIX } from './ToolTipButton.config';

/** @namespace Geely/Component/ToolTipButton/Component */
export class ToolTipButtonComponent extends JaidahToolTipButtonComponent {
    /**
     * Overridden to change Info icon
     */
    renderIcon() {
        return (
            <InfoIcon />
        );
    }

    render() {
        const {
            toggleTooltip,
            tooltipContent,
            id,
            showTooltip: show,
            hideTooltip: hide,
            isHoverable,
            setActive,
            hasCloseBtn,
            popupOpenDirection,
            isForceVisible,
            skipUnfreeze
        } = this.props;

        const showTooltip = isHoverable ? show : noopFn;
        const hideTooltip = isHoverable ? hide : noopFn;
        const clickHandle = !isHoverable ? toggleTooltip : noopFn;

        return (
            <div block="ToolTipButton">
                <button
                  onClick={ clickHandle }
                  onMouseEnter={ showTooltip }
                  onMouseLeave={ hideTooltip }
                  block="ToolTipButton"
                  elem="Icon"
                  type="button"
                >
                    { this.renderIcon() }
                </button>
                <ToolTipPopup
                  id={ `${TOOLTIP_POPUP_PREFIX}-${id}` }
                  clickOutside
                  direction={ popupOpenDirection }
                  shouldPopupClose={ false }
                  mix={ { block: 'ToolTip', elem: 'Popup' } }
                  setActive={ setActive }
                  hasCloseBtn={ hasCloseBtn }
                  isStatic={ isForceVisible }
                  hideTooltip={ hide }
                  skipUnfreeze={ skipUnfreeze }
                >
                    { tooltipContent }
                </ToolTipPopup>
            </div>

        );
    }
}

export default ToolTipButtonComponent;
