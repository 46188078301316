/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { Fragment } from 'react';

import ProductAttributeValue from 'Component/ProductAttributeValue';
import ProductMonthlyPrice from 'Component/ProductMonthlyPrice';
import { ProductAttributesComponent as JaidahProductAttributes }
from 'JaidahComponent/ProductAttributes/ProductAttributes.component';
import { formatPrice } from 'Util/Price';

import {
    ACCESSORIES, COLORS_GROUP, DETAILS_GROUP, SERVICE, UPGRADES_GROUP
} from './ProductAttributes.config';

import './ProductAttributes.geely.style';
import './ProductAttributes.popup.geely.style';

/** @namespace Geely/Component/ProductAttributes/Component */
export class ProductAttributesComponent extends JaidahProductAttributes {
    groupRenderMap = {
        [DETAILS_GROUP]: this.renderDetails.bind(this),
        [COLORS_GROUP]: this.renderColors.bind(this),
        [UPGRADES_GROUP]: this.renderUpgrades.bind(this)
    };

    renderGroup(attributes, title, price) {
        if (!attributes) {
            return null;
        }

        return (
            <div block="ProductAttributes" elem="AttributeHeader">
                <h2 block="ProductAttributes" elem="Group">
                    { title }
                </h2>
                { this.renderAttributes(attributes) }
                { price && (
                    <div block="ProductAttributes" elem="AttributeHeaderPrice">
                        { price }
                    </div>
                ) }
            </div>
        );
    }

    renderDetails() {
        const {
            attributesWithValues,
            price: {
                valueFormatted = ''
            },
            isSummaryStep
        } = this.props;

        return this.renderGroup(
            attributesWithValues,
            isSummaryStep ? __('Vehicle') : __('Technical details'),
            valueFormatted
        );
    }

    renderColors() {
        const {
            colorsWithValues
        } = this.props;

        return this.renderGroup(colorsWithValues, __('Color'));
    }

    // Custom attribute render for Upgrade group
    renderUpgradeItems() {
        const {
            categorizedItemsTotals: {
                [ACCESSORIES]: accessoriesTotal = 0,
                [SERVICE]: servicePackagesTotal = 0
            } = {},
            insurance: {
                value = 0
            } = {},
            optionTotalCurrency = 'QAR'
        } = this.props;

        return (
            <dl block="ProductAttributes" elem="Attributes">
                { servicePackagesTotal > 0 && this.renderAttribute(
                    { attribute_label: __('Service Package') },
                    formatPrice(servicePackagesTotal, optionTotalCurrency)
                ) }
                { accessoriesTotal > 0 && this.renderAttribute(
                    { attribute_label: __('Accessories') },
                    formatPrice(accessoriesTotal, optionTotalCurrency)
                ) }
                { value > 0 && this.renderAttribute(
                    { attribute_label: __('Insurance') },
                    formatPrice(value, optionTotalCurrency)
                ) }
            </dl>
        );
    }

    renderIndividualItems() {
        const {
            optionItems,
            insurance: {
                value = 0
            } = {},
            optionTotalCurrency = 'QAR'
        } = this.props;

        return (
            <dl block="ProductAttributes" elem="Attributes">
                { Object.keys(optionItems).map((key) => {
                    const {
                        name,
                        price_range: {
                            minimum_price: {
                                final_price: {
                                    currency = 'QAR',
                                    value = 0
                                } = {}
                            } = {}
                        }
                    } = optionItems[key];

                    return this.renderAttribute({ attribute_label: name }, formatPrice(value, currency));
                }) }
                { value > 0 && this.renderAttribute(
                    { attribute_label: __('Insurance') },
                    formatPrice(value, optionTotalCurrency)
                ) }
            </dl>
        );
    }

    renderUpgrades() {
        const {
            optionTotal = 0,
            optionTotalCurrency = 'QAR',
            isDealersOfferStep,
            insurance: {
                value = 0,
                product: {
                    id: insuranceProductId
                } = {}
            }
        } = this.props;

        if ((!optionTotal || optionTotal === 0) && (!insuranceProductId)) {
            return null;
        }

        if (isDealersOfferStep) {
            return (
                <div block="ProductAttributes" elem="AttributeHeader">
                    <h2 block="ProductAttributes" elem="Group">
                        { __('Upgrades') }
                    </h2>
                    { this.renderIndividualItems() }
                    <div block="ProductAttributes" elem="AttributeHeaderPrice">
                        { formatPrice(optionTotal + value, optionTotalCurrency) }
                    </div>
                </div>
            );
        }

        return (
            <div block="ProductAttributes" elem="AttributeHeader">
                <h2 block="ProductAttributes" elem="Group">
                    { __('Upgrades') }
                </h2>
                { this.renderUpgradeItems() }
                <div block="ProductAttributes" elem="AttributeHeaderPrice">
                    { formatPrice(optionTotal, optionTotalCurrency) }
                </div>
            </div>
        );
    }

    renderGroups() {
        return Object.keys(this.groupRenderMap).map(
            (key) => this.groupRenderMap[key]()
        );
    }

    renderAttribute(attribute, customValue) {
        return (
            <Fragment key={ attribute.attribute_label }>
                <div block="ProductAttributes" elem="AttributeLabel">
                    { attribute.attribute_label }
                </div>
                <div block="ProductAttributes" elem="ValueLabel">
                    { customValue || (
                        <ProductAttributeValue
                          key={ attribute.attribute_label }
                          attribute={ attribute }
                          isFormattedAsText
                        />
                    ) }
                </div>
            </Fragment>
        );
    }

    renderTotals() {
        const
            {
                price: {
                    value,
                    currency
                },
                optionTotal
            } = this.props;

        const totalValue = value + optionTotal;

        return (
            <div block="ProductAttributes" elem="ProductTotalAmount">
                <div block="ProductTotalAmount" elem="Text">
                    { __('Total Amount:') }
                </div>
                <div block="ProductTotalAmount" elem="ValueContainer">
                    <div block="ProductTotalAmount" elem="Value">
                        <span>{ formatPrice(totalValue, currency) }</span>
                    </div>
                    <ProductMonthlyPrice
                      block="ProductTotalAmount"
                      elem="SecondaryValue"
                      price={ totalValue }
                      currency={ currency }
                      isInfoButton={ false }
                      id="ProdAttr"
                    />
                </div>

            </div>
        );
    }

    renderContentInsideAttributes() {
        const { isSummaryStep, isDealersOfferStep } = this.props;
        return (
            <>
                { this.renderGroups() }
                { !isSummaryStep && !isDealersOfferStep && this.renderTotals() }
            </>
        );
    }
}

export default ProductAttributesComponent;
