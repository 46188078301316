import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as jaidahMapStateToProps,
    PopupContainer as JaidahPopupContainer
} from 'JaidahComponent/Popup/Popup.container';

export {
    mapDispatchToProps
};

/** @namespace Geely/Component/Popup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...jaidahMapStateToProps(state),
    payload: state.PopupReducer.popupPayload
});

/** @namespace Geely/Component/Popup/Container */
export class PopupContainer extends JaidahPopupContainer {
    containerProps() {
        const { isNotClickOutside = false, payload, activeOverlay } = this.props;
        const { isTooltipMobile } = payload[activeOverlay] || {};

        return {
            ...super.containerProps(),
            isNotClickOutside,
            isTooltipMobile
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
