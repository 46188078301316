export * from 'JaidahComponent/ProductActions/ProductActions.config';

export const TRIM = 'geely_trim';
export const MODEL_YEAR = 'geely_model_year';
export const IMAGE = 'geely_image';
export const ENGINE = 'geely_engine';
export const COLOR_INTERIOR = 'geely_color_interior';
export const COLOR_EXTERIOR = 'geely_color_exterior';
export const CAR_MODEL = 'geely_model';
export const TRANSMISSION = 'geely_transmission';
export const FUEL = 'geely_fuel_type';
export const TRACTION = 'geely_traction';
export const TR_TYPE = 'geely_transmission_type';
export const MAX_POWER = 'geely_max_power';
export const MAX_TORQUE = 'geely_max_torque';
export const WHEEL = 'geely_wheel';
export const DISPLACEMENT = 'geely_displacement';

export const EXTERIOR_MEDIA_TYPE = 'exterior';
export const INTERIOR_MEDIA_TYPE = 'interior';
export const BASE_MEDIA_TYPE = 'image';

// This array is needed for getMinimumConfigurableAttrPrice function, need to add all attribute codes here
export const ATTRIBUTE_CODES_ARRAY = [TRIM, ENGINE, COLOR_INTERIOR, COLOR_EXTERIOR];

// Step names
export const TRIM_STEP = 'TRIM_STEP';
export const ENGINE_STEP = 'ENGINE_STEP';
export const COLOR_STEP = 'COLOR_STEP';
export const ACCESSORIES_STEP = 'ACCESSORIES_STEP';
export const INSURANCE_STEP = 'INSURANCE_STEP';
export const SUMMARY_STEP = 'SUMMARY_STEP';
export const ADD_TO_CART_STEP = 'ADD_TO_CART_STEP';
export const SERVICE_PACKAGES_STEP = 'SERVICE_PACKAGES_STEP';

export const ATTRIBUTES_CODE_MAP = {
    [TRIM]: { code: TRIM, label: () => __('Trim') },
    [ENGINE]: { code: ENGINE, label: () => __('Engine') },
    [COLOR_EXTERIOR]: {
        code: COLOR_EXTERIOR,
        label: () => __('Exterior'),
        mediaType: EXTERIOR_MEDIA_TYPE
    },
    [COLOR_INTERIOR]: {
        code: COLOR_INTERIOR,
        label: () => __('Interior'),
        mediaType: INTERIOR_MEDIA_TYPE
    }
};

export const STEPS_MAP = {
    [TRIM_STEP]: {
        number: 1,
        attribute_codes: [
            ATTRIBUTES_CODE_MAP[TRIM],
            ATTRIBUTES_CODE_MAP[ENGINE],
            ATTRIBUTES_CODE_MAP[COLOR_EXTERIOR],
            ATTRIBUTES_CODE_MAP[COLOR_INTERIOR]
        ],
        label: () => __('Trims'),
        advance_button_text: () => __('Next step'),
        isLoadedInLeftMenu: true
    },
    [SERVICE_PACKAGES_STEP]: {
        number: 2,
        attribute_codes: [{ code: '', label: '' }],
        label: () => __('Service packages'),
        advance_button_text: () => __('Next step'),
        popUp: {
            enabled: true,
            popup_button_text: () => __('View Selection'),
            title: __('Selection')
        },
        last: false,
        isLoadedInLeftMenu: true
    },
    [ACCESSORIES_STEP]: {
        number: 3,
        attribute_codes: [{ code: '', label: '' }],
        advance_button_text: () => __('Next step'),
        label: () => __('Accessories'),
        popUp: {
            enabled: true,
            popup_button_text: () => __('View Selection'),
            title: __('Selection')
        },
        last: false,
        isLoadedInLeftMenu: true
    },
    [INSURANCE_STEP]: {
        number: 4,
        attribute_codes: [{ code: '', label: '' }],
        advance_button_text: () => __('Continue without insurance'),
        advance_button_text_alt: () => __('Continue'),
        isNextButtonSecondary: true,
        label: () => __('Insurance'),
        popUp: {
            enabled: true,
            popup_button_text: () => __('View Selection'),
            title: __('Insurance totals')
        },
        last: false,
        isLoadedInLeftMenu: true
    },
    [SUMMARY_STEP]: {
        number: 5,
        attribute_codes: [{ code: '', label: '' }],
        label: '',
        advance_button_text: () => __('Save'),
        isLoadedInLeftMenu: false,
        last: true
    },
    [ADD_TO_CART_STEP]: {
        number: 6,
        attribute_codes: [{ code: '', label: '' }],
        label: '',
        buttonText: () => __('Add to cart'),
        loadingText: __('Adding...'),
        isLoadedInLeftMenu: false
    }
};

// Order of steps to render accordingly
export const STEPS_ORDER = [TRIM_STEP, SERVICE_PACKAGES_STEP, ACCESSORIES_STEP, INSURANCE_STEP, SUMMARY_STEP];
