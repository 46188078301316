import {
    ProductConfigurableAttributesComponent as JaidahProductConfigurableAttributesComponent
} from 'JaidahComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.geely.style';

/** @namespace Geely/Component/ProductConfigurableAttributes/Component */
export class ProductConfigurableAttributesComponent extends JaidahProductConfigurableAttributesComponent {}

export default ProductConfigurableAttributesComponent;
