/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import {
    ACCESSORIES_STEP,
    ADD_TO_CART_STEP,
    INSURANCE_STEP,
    SERVICE_PACKAGES_STEP,
    STEPS_MAP,
    STEPS_ORDER,
    SUMMARY_STEP
} from 'Component/ProductActions/ProductActions.config';
import {
    AddToCartComponent as JaidahAddToCartComponent
} from 'JaidahComponent/AddToCart/AddToCart.component';
import { scrollToTop } from 'Util/Browser';

import './AddToCart.geely.style';

/** @namespace Geely/Component/AddToCart/Component */
export class AddToCartComponent extends JaidahAddToCartComponent {
    /**
     * Overridden to remove chevron icon
     */
    render() {
        const {
            layout,
            isDisabled,
            isAdding,
            currentStepNumber,
            moveToNextStep,
            isPdp = false,
            paramsViewed,
            onSaveClick,
            updateLinkedProducts,
            isInsuranceSelected,
            activeOverlay,
            hideActiveOverlay
        } = this.props;

        const attribs = {};
        const addToCartAttribs = STEPS_MAP[ADD_TO_CART_STEP];

        // If the AddToCart button is rendered on PDP determine the current step and set the button handler and text accordingly
        if (isPdp) {
            const currentStep = STEPS_ORDER[currentStepNumber - 1];
            const {
                advance_button_text = '',
                advance_button_text_alt = '',
                isNextButtonSecondary
            } = STEPS_MAP[currentStep];
            const last = currentStepNumber === Object.keys(STEPS_MAP).length;

            attribs.onClickHandler = () => {
                if ([ACCESSORIES_STEP, SERVICE_PACKAGES_STEP].includes(STEPS_ORDER[currentStepNumber])) {
                    updateLinkedProducts();
                }

                if (activeOverlay) {
                    hideActiveOverlay();
                }

                moveToNextStep();
                scrollToTop();
            };
            attribs.buttonText = typeof advance_button_text === 'function'
                ? advance_button_text()
                : advance_button_text;
            attribs.isHollow = isNextButtonSecondary;

            if (currentStep === INSURANCE_STEP && isInsuranceSelected) {
                attribs.buttonText = typeof advance_button_text_alt === 'function'
                    ? advance_button_text_alt()
                    : advance_button_text_alt;

                attribs.isHollow = false;
            }
            attribs.loadingText = __('Loading next step...');

            // if the user is at the last step and went through all the steps, render the add to cart button
            if (last && paramsViewed === STEPS_ORDER.length) {
                Object.assign(attribs, addToCartAttribs);
                attribs.onClickHandler = onSaveClick;
                attribs.loadingText = __('Selecting...');
            } else if (currentStep === SUMMARY_STEP) {
                attribs.onClickHandler = onSaveClick;
                attribs.loadingText = __('Selecting...');
            }

            if (isDisabled) {
                attribs.buttonText = __('Out of stock');
            }
        } else {
            Object.assign(attribs, addToCartAttribs);
            attribs.onClickHandler = onSaveClick;
            attribs.buttonText = __('Build');
            attribs.loadingText = __('Building...');
        }

        return (
            <button
              onClick={ attribs.onClickHandler }
              block="AddToCart"
              mods={ { layout } }
              mix={ { block: 'Button', mods: { isHollow: attribs.isHollow } } }
              disabled={ isDisabled || isAdding }
            >
                { this.renderCartIcon() }
                <span>{ isAdding ? attribs.loadingText : attribs.buttonText }</span>
            </button>
        );
    }
}

export default AddToCartComponent;
