/* eslint-disable react/prop-types */
import { lazy, PureComponent, Suspense } from 'react';

export const FontEnglish = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "font-english" */
    'Component/FontEnglish'
));

export const FontArabic = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "font-arabic" */
    'Component/FontArabic'
));

/** @namespace Geely/Component/FontLoader/Component */
export class FontLoaderComponent extends PureComponent {
    render() {
        const { children } = this.props;
        const { dir } = document.documentElement;

        return (
            <>
                <Suspense fallback="">
                    { dir === 'ltr' && <FontEnglish /> }
                    { dir === 'rtl' && <FontArabic /> }
                </Suspense>
                { children }
            </>
        );
    }
}

export default FontLoaderComponent;
