import {
    App as JaidahApp
} from 'JaidahComponent/App/App.component';

import FontLoader from '../FontLoader';

/** @namespace Geely/Component/App/Component */
export class AppComponent extends JaidahApp {
    render() {
        return (
            <FontLoader>
                { super.render() }
            </FontLoader>
        );
    }
}

export default AppComponent;
