import CloseIcon from 'Component/CloseIcon';
import {
    RemoveOptionButtonComponent as JaidahRemoveOptionButtonComponent
} from 'JaidahComponent/RemoveOptionButton/RemoveOptionButton.component';

/** @namespace Geely/Component/RemoveOptionButton/Component */
export class RemoveOptionButtonComponent extends JaidahRemoveOptionButtonComponent {
    render() {
        const { handleRemoveOption } = this.props;

        return (
            <button onClick={ handleRemoveOption } block="RelatedProductTotals" elem="DeleteBtn">
                <CloseIcon />
            </button>
        );
    }
}

export default RemoveOptionButtonComponent;
