/* eslint-disable react/boolean-prop-naming */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay, hideActivePopup } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';

import ToolTipButton from './ToolTipButton.component';
import { TOOLTIP_POPUP_PREFIX } from './ToolTipButton.config';

/** @namespace Scandipwa/Component/ToolTipButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace Scandipwa/Component/ToolTipButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showToolTipPopup: (id, payload = {}) => dispatch(showPopup(id, payload)),
    hideActivePopup: () => dispatch(hideActivePopup()),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Scandipwa/Component/ToolTipButton/Container */
export class ToolTipButtonContainer extends PureComponent {
    static propTypes = {
        tooltipContent: PropTypes.node.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        showToolTipPopup: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        hideActivePopup: PropTypes.func.isRequired,
        isHoverable: PropTypes.bool,
        hasCloseBtn: PropTypes.bool,
        isMobile: PropTypes.bool.isRequired,
        popupOpenDirection: PropTypes.string,
        activeOverlay: PropTypes.string.isRequired
    };

    static defaultProps = {
        isHoverable: false,
        hasCloseBtn: true,
        popupOpenDirection: 'top'
    };

    state = {
        isActive: false,
        isForceVisible: false
    };

    containerFunctions = {
        toggleTooltip: this.toggleTooltip.bind(this),
        showTooltip: this.showTooltip.bind(this),
        hideTooltip: this.hideTooltip.bind(this),
        setActive: this.setActive.bind(this, false)
    };

    toggleTooltip(e) {
        const { isActive } = this.state;
        if (!isActive) {
            this.showTooltip(e);
        } else {
            this.hideTooltip(e);
        }
    }

    showTooltip() {
        const {
            id,
            showToolTipPopup,
            activeOverlay
        } = this.props;
        const tooltipPopupName = `${TOOLTIP_POPUP_PREFIX}-${id}`;

        if (activeOverlay !== tooltipPopupName) {
            this.setState({ isForceVisible: true });
        } else {
            showToolTipPopup(tooltipPopupName);
        }

        this.setActive(true);
    }

    hideTooltip() {
        const {
            hideActiveOverlay,
            hideActivePopup,
            activeOverlay,
            id
        } = this.props;
        const tooltipPopupName = `${TOOLTIP_POPUP_PREFIX}-${id}`;

        if (activeOverlay !== tooltipPopupName) {
            this.setState({ isForceVisible: false });
        } else {
            hideActiveOverlay();
            hideActivePopup();
        }

        this.setActive(false);
    }

    setActive(active) {
        this.setState({ isActive: active });
    }

    containerProps() {
        const { isActive, isForceVisible } = this.state;
        const {
            tooltipContent,
            id,
            isHoverable,
            hasCloseBtn,
            isMobile,
            popupOpenDirection
        } = this.props;

        return {
            isActive,
            tooltipContent,
            id,
            isHoverable,
            hasCloseBtn,
            isMobile,
            popupOpenDirection,
            isForceVisible
        };
    }

    render() {
        return (
            <ToolTipButton
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolTipButtonContainer);
