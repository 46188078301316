import { AR_LANGUAGE, QAR_AR } from 'JaidahUtil/Price/Price';

export * from 'JaidahUtil/Price/Price';

/**
 * Overriden to change minimumFractionDigits
*/
/** @namespace Geely/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'USD', minFractionDigits = 2) => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;
    const minimumFractionDigits = price === 0 ? 0 : minFractionDigits;

    const priceFormatted = new Intl.NumberFormat(language, {
        style: 'currency', currency, minimumFractionDigits
    }).format(price);

    return document.documentElement.lang === AR_LANGUAGE && currency === 'QAR'
        ? `${priceFormatted.replace('QAR', '')} ${QAR_AR } ` : priceFormatted;
};
