import { connect } from 'react-redux';

import {
    CompareTrimsContainer as JaidahCompareTrimsContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'JaidahComponent/CompareTrims/CompareTrims.container';

import {
    NAV_LEFT,
    NAV_RIGHT
} from './CompareTrims.config';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Geely/Component/CompareTrims/Container */
export class CompareTrimsContainer extends JaidahCompareTrimsContainer {
    // overridden to change the condition on arabic store view
    getIsScrolled() {
        const isRtl = document.dir === 'rtl';

        if (isRtl) {
            return this.tableRef.scrollLeft < 0;
        }

        return this.tableRef.scrollLeft > 0;
    }

    reverseDirection(direction) {
        if (direction === NAV_LEFT) {
            return NAV_RIGHT;
        }

        return NAV_LEFT;
    }

    // overridden to add scrollStateFactor to fix visibility check on arabic store view
    getScrollVisibility(direction) {
        const OFFSET_CORRECTION = 0.25;
        const isRtl = document.dir === 'rtl';
        const tableWidth = this.tableRef.firstChild?.offsetWidth;
        const maxPossibleScroll = tableWidth - this.tableRef?.offsetWidth - OFFSET_CORRECTION;
        const scrollStateFactor = isRtl ? -1 : 1;
        const scrollState = this.tableRef.scrollLeft * scrollStateFactor || 0;

        switch (direction) {
        case NAV_LEFT:
            return scrollState !== 0;
        case NAV_RIGHT:
            return scrollState <= maxPossibleScroll;
        default:
            return true;
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        reverseDirection: this.reverseDirection
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompareTrimsContainer);
