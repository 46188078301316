import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ProgressBar.style';

/** @namespace Geely/Component/ProgressBar/Component */
export class ProgressBarComponent extends PureComponent {
    static propTypes = {
        numberOfSteps: PropTypes.number.isRequired,
        currentStep: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired,
        breadcrumbItems: PropTypes.arrayOf(PropTypes.node).isRequired
    };

    render() {
        const {
            label,
            currentStep,
            numberOfSteps,
            breadcrumbItems,
            isMobile
        } = this.props;

        return (
            <div block="ProgressBar" mods={ { isMobile } }>
                <div block="ProgressBar" elem="Header">
                    <h3 block="ProgressBar" elem="Label">{ label }</h3>
                    <p block="ProgressBar" elem="Steps">
                        <span block="ProgressBar" elem="CurrentStep">{ currentStep }</span>
                        <span block="ProgressBar" elem="TotalSteps">{ `/ ${numberOfSteps}` }</span>
                    </p>
                </div>
                <div block="ProgressBar" elem="Breadcrumb">
                    { breadcrumbItems }
                </div>
            </div>
        );
    }
}

export default ProgressBarComponent;
