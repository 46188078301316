import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as JaidahProductPriceContainer
} from 'JaidahComponent/ProductPrice/ProductPrice.container';

export {
    mapDispatchToProps,
    mapStateToProps
};

/** @namespace Geely/Component/ProductPrice/Container */
export class ProductPriceContainer extends JaidahProductPriceContainer {
    containerProps() {
        const { isPlp } = this.props;

        return {
            ...super.containerProps(),
            isPlp
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
