import { FIELD_TYPE } from 'JaidahComponent/Field/Field.config';

export * from 'JaidahComponent/Field/Field.config';

export const NON_REGULAR_TYPES = [
    FIELD_TYPE.terms,
    FIELD_TYPE.checkbox,
    FIELD_TYPE.radio,
    FIELD_TYPE.send,
    FIELD_TYPE.file,
    FIELD_TYPE.image
];
