import { connect } from 'react-redux';

import {
    EstimatedDeliveryContainer as JaidahEstimatedDeliveryContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'JaidahComponent/EstimatedDelivery/EstimatedDelivery.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Geely/Component/EstimatedDelivery/Container */
export class EstimatedDeliveryContainer extends JaidahEstimatedDeliveryContainer {
    /**
     * Overridden to change dashes for spaces in result
     */
    getDate() {
        const {
            date: dateString,
            extraDays
        } = this.props;

        if (!dateString) {
            return dateString;
        }

        const date = new Date(dateString.replaceAll('-', '/'));

        date.setDate(date.getDate() + extraDays);

        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        const prefix = this.getDatePrefix(date);

        return `${prefix} ${month} ${year}`;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimatedDeliveryContainer);
