import {
    VALIDATION_INPUT_TYPE,
    VALIDATION_MESSAGES as sourceValidationMessages,
    VALIDATION_RULES as sourceValidationRules
} from 'JaidahUtil/Validator/Config';

export * from 'SourceUtil/Validator/Config';

export const VALIDATION_MESSAGES = {
    ...sourceValidationMessages,
    [VALIDATION_INPUT_TYPE.phone]: () => __('The phone number should be 8 digits.'),
    [VALIDATION_INPUT_TYPE.alphaSpace]: () => __('Only Latin characters are allowed!')
};

export const VALIDATION_RULES = {
    ...sourceValidationRules,
    [VALIDATION_INPUT_TYPE.phone]: /^[\\+]?[0-9]{8}$/im
};
