import {
    ACCOUNT_INFORMATION,
    FIRST_SECTION,
    MY_ACCOUNT,
    VERIFICATION_DETAILS,
    VERIFICATION_DETAILS_URL
} from 'Type/Account.type';

export const tabMap = (_member) => ({
    [MY_ACCOUNT]: {
        url: '',
        tabName: __('Edit Profile'),
        title: __('Edit Profile'),
        section: FIRST_SECTION
    },
    [ACCOUNT_INFORMATION]: {
        url: '/change-password',
        tabName: __('Change Password'),
        title: __('Change Password'),
        section: FIRST_SECTION
    },
    [VERIFICATION_DETAILS]: {
        url: VERIFICATION_DETAILS_URL,
        tabName: __('Verification Details'),
        title: __('Verification Details'),
        section: FIRST_SECTION
    }
});

export default {
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap
        }
    }
};
