/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import ChevronIcon from 'Component/ChevronIcon/ChevronIcon.component';
import { RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import { CarouselScrollArrow as SourceCarouselScroll }
from 'SourceComponent/CarouselScrollArrow/CarouselScrollArrow.component';

import './CarouselScrollArrow.geely.style';

/** @namespace Geely/Component/CarouselScrollArrow/Component */
export class CarouselScrollArrow extends SourceCarouselScroll {
    renderArrow() {
        return <ChevronIcon direction={ RIGHT } isGeelyArrow />;
    }

    render() {
        const { mods, onClick } = this.props;

        return (
            <button
              block="CarouselScrollArrow"
              mods={ mods }
              onClick={ onClick }
              aria-label="Arrow"
            >
                { this.renderArrow() }
            </button>
        );
    }
}

export default CarouselScrollArrow;
