import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as jaidahMapStateToProps,
    ToolTipButtonContainer as JaidahToolTipButtonContainer
} from 'JaidahComponent/ToolTipButton/ToolTipButton.container';

export {
    mapDispatchToProps
};

/** @namespace Geely/Component/ToolTipButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...jaidahMapStateToProps(state),
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace Geely/Component/ToolTipButton/Container */
export class ToolTipButtonContainer extends JaidahToolTipButtonContainer {
    static propTypes = {
        ...this.propTypes,
        skipUnfreeze: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        skipUnfreeze: false
    };

    state = {
        ...this.state,
        isForceVisible: false
    };

    containerProps() {
        const { skipUnfreeze } = this.props;

        return {
            ...super.containerProps(),
            skipUnfreeze
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolTipButtonContainer);
