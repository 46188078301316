import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps, ProductAttributesContainer as JaidahProductAttributes
}
from 'JaidahComponent/ProductAttributes/ProductAttributes.container';

export {
    mapDispatchToProps,
    mapStateToProps
};

/** @namespace Geely/Component/ProductAttributes/Container */
export class ProductAttributesContainer extends JaidahProductAttributes {
    containerProps() {
        const {
            optionsData: {
                categorizedItemsTotals = {},
                categorizedItems = {}
            } = {}
        } = this.props;

        return {
            ...super.containerProps(),
            categorizedItemsTotals,
            categorizedItems
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributesContainer);
