import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductStepsTabContainer as JaidahProductStepsTabContainer
} from 'JaidahComponent/ProductStepsTab/ProductStepsTab.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Geely/Component/ProductStepsTab/Container */
export class ProductStepsTabContainer extends JaidahProductStepsTabContainer {
    containerProps() {
        const {
            isChildrenOnly,
            isHeaderOnly
        } = this.props;

        return {
            ...super.containerProps(),
            isChildrenOnly,
            isHeaderOnly
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductStepsTabContainer);
