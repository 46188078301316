import { connect } from 'react-redux';

import {
    AddToCartContainer as SourceAddToCartContainer,
    CartDispatcher,
    LinkedProductsDispatcher,
    mapDispatchToProps as sourceMapDispatchProps,
    mapStateToProps as sourceMapStateToProps
} from 'JaidahComponent/AddToCart/AddToCart.container';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

export {
    CartDispatcher,
    LinkedProductsDispatcher
};

/** @namespace Geely/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace Geely/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchProps(dispatch),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Geely/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    containerProps() {
        const { activeOverlay, hideActiveOverlay } = this.props;
        return {
            ...super.containerProps(),
            activeOverlay,
            hideActiveOverlay
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
