import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchProps,
    mapStateToProps,
    ProductStepsContainer as JaidahProductStepsContainer
} from 'JaidahComponent/ProductSteps/ProductSteps.container';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { toggleScroll } from 'Util/Browser';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

export {
    mapStateToProps
};

/** @namespace Geely/Component/ProductSteps/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchProps(dispatch),
    toggleCompareBreadcrumbs: (areBreadcrumbsVisible) => dispatch(toggleBreadcrumbs(areBreadcrumbsVisible))
});

/** @namespace Geely/Component/ProductSteps/Container */
export class ProductStepsContainer extends JaidahProductStepsContainer {
    containerFunctions = {
        ...this.containerFunctions,
        handleBackMobile: this.handleBackMobile.bind(this)
    };

    containerProps() {
        const {
            isHeaderOnly,
            isChildrenOnly,
            productPrice,
            parentInStock
        } = this.props;

        return {
            ...super.containerProps(),
            isChildrenOnly,
            isHeaderOnly,
            productPrice,
            parentInStock
        };
    }

    handleBackMobile() {
        const {
            currentStepNumber,
            changeStep
        } = this.props;

        if (currentStepNumber > 1) {
            changeStep(currentStepNumber - 1);
        } else {
            history.push(appendWithStoreCode('/new-cars'));
        }
    }

    toggleCompareTrims(isVisible = false) {
        const { isCompareTrimsVisible } = this.state;
        const { activeProduct: { attributes } = {}, toggleCompareBreadcrumbs } = this.props;

        if ((isVisible === isCompareTrimsVisible) || (isVisible && !attributes)) {
            return;
        }

        this.setState(
            { isCompareTrimsVisible: isVisible },
            () => {
                toggleCompareBreadcrumbs(!isVisible);
                toggleScroll(!isVisible);
            }
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductStepsContainer);
