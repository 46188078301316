import {
    CloseIconComponent as JaidahCloseIconComponent
} from 'JaidahComponent/CloseIcon/CloseIcon.component';

import './CloseIcon.geely.style';

/** @namespace Geely/Component/CloseIcon/Component */
export class CloseIconComponent extends JaidahCloseIconComponent {
    render() {
        return (
            <svg
              block="CloseIcon"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_15703_4088)">
                    <path d="M1.5 10.5L10.5 1.5M1.5 1.5L10.5 10.5" strokeWidth="1.5" />
                </g>
                <defs>
                    <clipPath id="clip0_15703_4088">
                        <rect width="12" height="12" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    }
}

export default CloseIconComponent;
