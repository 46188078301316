/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { connect } from 'react-redux';

import {
    AccordionTabsContainer as JaidahAccordionTabsContainer,
    mapStateToProps
} from 'JaidahComponent/AccordionTabs/AccordionTabs.container';

export {
    mapStateToProps
};

/** @namespace Geely/Component/AccordionTabs/Container */
export class AccordionTabsContainer extends JaidahAccordionTabsContainer {
    renderContent(child, idx) {
        const { currentTab } = this.state;

        const { children } = child;
        const filteredChildren = this.filterChildren(children);
        const tabContent = filteredChildren.find(
            ({ attribs: { class: className } }) => className.includes('AccordionContent')
        );
        const { children: tabContentChildren } = tabContent;
        const tabImage = tabContentChildren.find(
            ({ attribs: { class: className = '' } = {} }) => className.includes('AccordionImage')
        );

        return (
            <div
              { ...attributesToProps(tabContent?.attribs) }
              mix={ {
                  block: 'AccordionTabs',
                  elem: 'TabContent',
                  mods: { isVisible: currentTab === idx, isWithImage: !!tabImage }
              } }
            >
                { domToReact(tabContentChildren) }
            </div>
        );
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps)(AccordionTabsContainer);
