import {
    SomethingWentWrongComponent as JaidahSomethingWentWrong
} from 'JaidahRoute/SomethingWentWrong/SomethingWentWrong.component';

import './SomethingWentWrong.geely.style';

/** @namespace Geely/Route/SomethingWentWrong/Component */
export class SomethingWentWrongComponent extends JaidahSomethingWentWrong {}

export default SomethingWentWrongComponent;
