import {
    getInitialState,
    ProductListReducer as sourceProductListReducer,
    reduceFilters as sourceReduceFilters
} from 'SourceStore/ProductListInfo/ProductListInfo.reducer';
import { UPDATE_PRODUCT_LIST_INFO } from 'Store/ProductListInfo/ProductListInfo.action';

export { getInitialState };

/** @namespace Geely/Store/ProductListInfo/Reducer/reduceFilters */
export const reduceFilters = (filters) => {
    const initFilters = sourceReduceFilters(filters);
    const { price } = initFilters;

    // To render price slider as first element
    return {
        ...initFilters,
        price: {
            ...price,
            attribute_position: 0,
            attribute_label: __('Price, QAR')
        }
    };
};

/** @namespace Geely/Store/ProductListInfo/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_PRODUCT_LIST_INFO: {
        const {
            products: {
                filters: availableFilters = []
            } = {}
        } = action;

        // Overridden to save previous state if no products are found
        if (availableFilters.length === 0) {
            return {
                ...state,
                isLoading: false
            };
        }

        return {
            ...state,
            ...sourceProductListReducer(state, action),
            filters: reduceFilters(availableFilters)
        };
    }

    default:
        return {
            ...state,
            ...sourceProductListReducer(state, action)
        };
    }
};

export default ProductListReducer;
