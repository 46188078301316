/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT } from 'Component/ChevronIcon/ChevronIcon.config';
import { STEPS_MAP, STEPS_ORDER } from 'Component/ProductActions/ProductActions.config';
import {
    ProductStepsTabComponent as JaidahProductStepsTabComponent
} from 'JaidahComponent/ProductStepsTab/ProductStepsTab.component';
import { noopFn } from 'Util/Common';

import './ProductStepsTab.geely.style';

/** @namespace Geely/Component/ProductStepsTab/Component */
export class ProductStepsTabComponent extends JaidahProductStepsTabComponent {
    stepsTitleMap = STEPS_ORDER.reduce((acc, step, index) => {
        if (index === 0) {
            return {
                ...acc,
                [step]: __('All models')
            };
        }

        return {
            ...acc,
            [step]: STEPS_MAP[STEPS_ORDER[index - 1]].label()
        };
    }, {});

    renderTabHeader() {
        const {
            stepCode,
            handleMobileBack,
            isMobile,
            isPopUpOnMobile,
            triggerPopup,
            popUpAttributes: {
                enabled,
                popup_button_text
            } = {},
            isChildrenOnly = false
        } = this.props;

        if (isChildrenOnly || !isMobile) {
            return null;
        }

        if (isPopUpOnMobile && enabled) {
            const popupButtonText = typeof popup_button_text === 'function' ? popup_button_text() : popup_button_text;

            return (
                <div
                  block="ProductStepsTab"
                  elem="TabHeader"
                >
                    <div
                      role="button"
                      onClick={ handleMobileBack }
                      block="ProductStepsTab"
                      elem="TabHeaderTextWrapper"
                    >
                        <ChevronIcon direction={ LEFT } />
                        <span block="TabHeaderText">{ this.stepsTitleMap[stepCode] }</span>
                    </div>
                    <button
                      onClick={ triggerPopup }
                      block="Button"
                      mods={ { isHollow: true } }
                      mix={ { block: 'ProductStepsTab', elem: 'PopupButton' } }
                    >
                        { popupButtonText }
                    </button>
                    { this.renderPopUp() }
                </div>
            );
        }

        return (
            <div
              role="button"
              onClick={ handleMobileBack }
              block="ProductStepsTab"
              elem="TabHeader"
            >
                <div block="ProductStepsTab" elem="TabHeaderTextWrapper">
                    <ChevronIcon direction={ LEFT } />
                    <span block="TabHeaderText">{ this.stepsTitleMap[stepCode] }</span>
                </div>
            </div>
        );
    }

    render() {
        const {
            active = false,
            handleOnClick,
            isBehind = false,
            children = [],
            isMobile,
            isPopUpOnMobile,
            isNextStep,
            inStock,
            stepCode,
            isHeaderOnly = false
        } = this.props;
        const clickHandler = active || isMobile ? noopFn : handleOnClick;
        const isClickable = (isBehind || isNextStep) && inStock;
        const childrenToRender = isPopUpOnMobile && isMobile ? null : children;

        if (isHeaderOnly) {
            return this.renderTabHeader();
        }

        return (
            <div
              role="button"
              onClick={ clickHandler }
              block="ProductStepsTab"
              elem="Tab"
              mods={ { active, isClickable, stepCode } }
            >
                { this.renderTabHeader() }
                <div block="ProductStepsTab" elem="TabContent">
                    { childrenToRender }
                </div>
            </div>
        );
    }
}

export default ProductStepsTabComponent;
