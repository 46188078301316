import { withRouter } from 'react-router';

import CarouselScroll from 'Component/CarouselScroll';
import { CAROUSEL_SLIDE_COUNT } from 'Component/CarouselScroll/CarouselScroll.config';
import Slider from 'Component/Slider';
import VideoPopup from 'Component/VideoPopup';
import {
    ProductGalleryComponent as SourceProductGallery
} from 'SourceComponent/ProductGallery/ProductGallery.component';

import './ProductGallery.geely.style';

/** @namespace Geely/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGallery {
    renderAdditionalPictures() {
        const {
            gallery,
            isImageZoomPopupActive,
            activeImage,
            onActiveImageChange,
            isWithEmptySwitcher
        } = this.props;

        if (gallery.length === 1) {
            return <div block="ProductGallery" elem="Additional" mods={ { isWithEmptySwitcher } } />;
        }

        return (
            <div block="ProductGallery" elem="Additional" mods={ { isImageZoomPopupActive, children: gallery.length } }>
                <CarouselScroll
                  activeItemId={ activeImage }
                  onChange={ onActiveImageChange }
                  showedItemCount={ CAROUSEL_SLIDE_COUNT }
                  isImageZoomPopupActive={ isImageZoomPopupActive }
                >
                    { gallery.map(this.renderAdditionalPicture.bind(this)) }
                </CarouselScroll>
            </div>
        );
    }

    renderGeelySlider() {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            isMobile
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: false
        };

        const isMoreThanOnePhoto = gallery.length > 1;

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery"
              elem="SliderWrapper"
            >
                { this.renderZoomIcon() }
                <meta itemProp="image" content={ this.getImageUrl() } />
                <Slider
                  sliderRef={ sliderRef }
                  mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                  showCrumbs={ isMobile && isMoreThanOnePhoto }
                  showArrows={ isImageZoomPopupActive }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isInteractionDisabled={ isZoomEnabled }
                  onClick={ this.handleSliderClick }
                  sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                  isHeightTransitionDisabledOnMount
                >
                    { gallery.map(this.renderSlide) }
                </Slider>
            </div>
        );
    }

    renderZoomIcon() {
        const { toggleImageZoomPopupActive, isMobile } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <button block="ProductGallery" elem="GalleryZoomIcon" onClick={ toggleImageZoomPopupActive }>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8.74996" cy="8.74984" r="6.33333" stroke="white" strokeWidth="1.5" />
                    <path d="M17.9167 17.9168L13.3334 13.3335" stroke="white" strokeWidth="1.5" />
                    <path d="M8.75 5.8335V11.6668" stroke="white" strokeWidth="1.5" />
                    <path d="M5.83337 8.75L11.6667 8.75" stroke="white" strokeWidth="1.5" />
                </svg>
            </button>
        );
    }

    render() {
        return (
            <div block="ProductGallery" ref={ this.galleryRef }>
                { this.renderGeelySlider() }
                { this.renderAdditionalPictures() }
                <VideoPopup />
            </div>
        );
    }
}

export default withRouter(ProductGalleryComponent);
