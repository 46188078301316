import ToolTipButton from 'Component/ToolTipButton';
import {
    ProductMonthlyPriceComponent as JaidahProductMonthlyPriceComponent
} from 'JaidahComponent/ProductMonthlyPrice/ProductMonthlyPrice.component';

import './ProductMonthlyPrice.geely.style.scss';

/** @namespace Geely/Component/ProductMonthlyPrice/Component */
export class ProductMonthlyPriceComponent extends JaidahProductMonthlyPriceComponent {
    renderToolTipContent() {
        const {
            downpayment,
            installments
        } = this.props;

        const itemList = [
            {
                label: __('Down payment:'),
                value: `${downpayment}%`
            },
            {
                label: __('Number of installments:'),
                value: installments
            }
        ];

        return (
            <div block="MonthlyPaymentTooltip" elem="Wrapper">
                { itemList.map((item) => this.renderToolTipItem(item)) }
            </div>
        );
    }

    render() {
        const {
            block, elem, isEnabled, id,
            isInfoButton,
            isMobile,
            isAttributePopupOpen,
            isSummaryStep,
            popupOpenDirection,
            isMyVehicles
        } = this.props;

        if (!isEnabled) {
            return null;
        }

        if (isMobile && (!isAttributePopupOpen && !isSummaryStep) && !isMyVehicles) {
            return null;
        }

        return (
            <div block={ block } elem={ elem } mix={ { block: 'ProductMonthlyPrice' } }>
                <div
                  block="ProductMonthlyPrice"
                  elem="PriceWrapper"
                >
                    { this.renderPricePerMonth() }
                    { isInfoButton && (
                        <ToolTipButton
                          hasCloseBtn={ false }
                          popupOpenDirection={ popupOpenDirection }
                          isHoverable={ !isMobile }
                          tooltipContent={ this.renderToolTipContent() }
                          skipUnfreeze={ !(isMobile || isMyVehicles) }
                          id={ id }
                        />
                    ) }
                </div>
            </div>
        );
    }
}

export default ProductMonthlyPriceComponent;
