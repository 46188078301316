import ShowroomBookingQuery from 'Query/ShowroomBooking.query';
import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/**
 * MyAccount Mutations
 *
 * @class MyAccount
 * @namespace Scandipwa/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    /**
     * Get ResetPassword mutation
     * @param {{token: String, password: String, password_confirmation: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field}
     * @memberof MyAccount
     */
    getResetPasswordMutation(options) {
        const {
            token, password, password_confirmation, email = '', customer_id = 0
        } = options;

        return new Field('s_resetPasswordOld')
            .addArgument('token', 'String!', token)
            .addArgument('password', 'String!', password)
            .addArgument('email', 'String', email)
            .addArgument('customer_id', 'Int', customer_id)
            .addArgument('password_confirmation', 'String!', password_confirmation)
            .addField('status');
    }

    _getCustomerFields() {
        return [
            ...super._getCustomerFields(),
            'phone',
            'qid',
            'salutation',
            'street',
            'city',
            'userExistingCustomer',
            'userLifetimeOrders',
            'userLifetimeValue'
        ];
    }

    getGenerateLoginOTPCodeMutation(options) {
        const {
            phone,
            qid,
            quote_id
        } = options;

        return new Field('generateLoginOTPCode')
            .addArgument('phone', 'String!', phone)
            .addArgument('qid', 'String!', qid)
            .addArgument('quote_id', 'String', quote_id);
    }

    getGenerateCustomerTokenOTPMutation(options) {
        const {
            phone,
            qid,
            otp_code,
            quote_id = ''
        } = options;

        return new Field('generateCustomerTokenOTP')
            .addArgument('phone', 'String!', phone)
            .addArgument('qid', 'String!', qid)
            .addArgument('otp_code', 'String!', otp_code)
            .addArgument('quote_id', 'String', quote_id)
            .addFieldList(['token', 'showPasswordResetOnLogin', 'passwordResetToken', 'showEmailField', 'customerId']);
    }

    getCreateAccountMutation(options) {
        const {
            customer,
            password,
            showroomForm
        } = options;

        const customerInput = { ...customer, password };
        if (showroomForm) {
            customerInput.showroomForm = showroomForm;
        }

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', customerInput)
            .addField(this._getCustomerField())
            .addField(this._getBookingResultField());
    }

    getPrefilledDataFields() {
        return [
            'salutation',
            'firstName',
            'lastName',
            'qid',
            'phone',
            'street'
        ];
    }

    getIsEmailAvailable(options) {
        const { email } = options;

        return new Field('isEmailAvailable')
            .addArgument('email', 'String!', email)
            .addField('is_email_available');
    }

    _getBookingResultField() {
        return new Field('bookingResult')
            .addFieldList(ShowroomBookingQuery._getBookShowroomFields());
    }
}

export default new MyAccountQuery();
