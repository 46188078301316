import ProductMonthlyPrice from 'Component/ProductMonthlyPrice';
import { DealersOfferStepComponent as SourceDealersOfferStep }
from 'JaidahComponent/DealersOfferStep/DealersOfferStep.component';
import { formatPrice } from 'Util/Price/Price';

import './DealersOfferStep.geely.style';
/** @namespace Geely/Component/DealersOfferStep/Component */
export class DealersOfferStepComponent extends SourceDealersOfferStep {
    renderActions() {
        return (
            <div block="DealersOfferStep" elem="ButtonBlock">
                <button
                  type="submit"
                  block="Button"
                  mix={ { block: 'CheckoutBilling', elem: 'Button' } }
                >
                    { __('Next') }
                </button>
            </div>
        );
    }

    renderTotalBlock() {
        const {
            baseOrderInfo: { grand_total = '', currency_code = 'USD' },
            isMobile
        } = this.props;

        const price = formatPrice(grand_total, currency_code);

        const totalTextContent = isMobile ? __('Total') : __('Total Amount');

        return (
            <div block="DealersOfferStep" elem="TotalInfoBlock">
                <div block="DealersOfferStep" elem="TotalText">
                    <span>{ totalTextContent }</span>
                </div>
                <div block="DealersOfferStep" elem="TotalAmountBlock">
                    <span block="DealersOfferStep" elem="TotalAmount">{ price }</span>
                    <ProductMonthlyPrice
                      block="DealersOfferStep"
                      elem="TotalAmountPerMonth"
                      price={ grand_total }
                      currency={ currency_code }
                      id="DealerOffer"
                      isMyVehicles
                    />
                </div>
            </div>
        );
    }
}

export default DealersOfferStepComponent;
