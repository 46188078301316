import { Route, Switch } from 'react-router-dom';

import { BEFORE_ITEMS_TYPE, HEADER } from 'Component/Router/Router.config';
import {
    Breadcrumbs,
    Header,
    MyVehicles,
    OrderReservation,
    RouterComponent as JaidahRouterComponent,
    ServiceBooking,
    ShowroomBooking,
    SmsCodePopup,
    TestdriveBooking,
    withStoreRegex
} from 'JaidahComponent/Router/Router.component';

export {
    SmsCodePopup,
    MyVehicles,
    OrderReservation,
    ServiceBooking,
    ShowroomBooking,
    TestdriveBooking,
    Breadcrumbs,
    withStoreRegex
};

/** @namespace Geely/Component/Router/Component */
export class RouterComponent extends JaidahRouterComponent {
    // Render switch as header component to disable rendering on home page
    [BEFORE_ITEMS_TYPE] = this[BEFORE_ITEMS_TYPE].map((item) => {
        const { name } = item;

        if (name === HEADER) {
            return {
                ...item,
                component: this.renderHeaderSwitch()
            };
        }

        return item;
    });

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct(props) {
        super.__construct(props);

        // To not create new function every time in JSX
        // component when using bind inside of render={}
        this.renderMobileHeader = this.renderMobileHeader.bind(this);
    }

    renderHeader() {
        return <Header />;
    }

    renderMobileHeader() {
        const { isMobile } = this.props;

        if (!isMobile) {
            return null;
        }

        return this.renderHeader();
    }

    renderHeaderSwitch() {
        return (
            <Switch>
                <Route exact path={ withStoreRegex('/') } render={ this.renderMobileHeader } />
                <Route path="*" render={ this.renderHeader } />
            </Switch>
        );
    }
}

export default RouterComponent;
