import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FieldFileContainer as SourceFieldFileContainer } from 'SourceComponent/FieldFile/FieldFile.container';
import { noopFn } from 'Util/Common';

/** @namespace Scandipwa/Component/FieldFile/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/FieldFile/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/FieldFile/Container */
export class FieldFileContainer extends SourceFieldFileContainer {
    static propTypes = {
        ...SourceFieldFileContainer.propTypes,
        hasTooltip: PropTypes.bool,
        tooltipContent: PropTypes.node,
        isMobile: PropTypes.bool,
        subLabel: PropTypes.string,
        validate: PropTypes.func,
        resetFieldValue: PropTypes.func
    };

    static defaultProps = {
        ...SourceFieldFileContainer.defaultProps,
        hasTooltip: false,
        tooltipContent: null,
        isMobile: true,
        subLabel: '',
        validate: noopFn,
        resetFieldValue: noopFn
    };

    __construct(props) {
        const { attr: { fileName } } = props;

        this.state = {
            ...this.state,
            fileName
        };
    }

    clearFile() {
        const {
            attr: { name },
            events: { onFileFlush } = {}
        } = this.props;

        this.setState({
            fileName: '',
            isLoading: false,
            value: ''
        });

        this.fieldRef.fileData = JSON.stringify({
            file_data: '',
            file_name: ''
        });

        this.fieldRef.value = null;

        if (onFileFlush) {
            onFileFlush(name);
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        clearFile: this.clearFile.bind(this)
    };

    containerProps() {
        const {
            events: {
                // eslint-disable-next-line no-unused-vars
                onFileFlush,
                ...events
            },
            attr: {
                // eslint-disable-next-line no-unused-vars
                fileName,
                autoComplete,
                autocomplete,
                additionalField,
                ...attr
            } = {},
            label,
            hasTooltip,
            tooltipContent,
            renderErrorPopup,
            isMobile,
            subLabel
        } = this.props;

        return {
            ...super.containerProps(),
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete
            },
            events: {
                ...events,
                onChange: this.onChange.bind(this)
            },
            label,
            hasTooltip,
            tooltipContent,
            renderErrorPopup,
            isMobile,
            subLabel,
            additionalField
        };
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FieldFileContainer);
