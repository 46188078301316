import { withRouter } from 'react-router-dom';

import {
    LoginAccountComponent as JaidahLoginAccountComponent
} from 'JaidahRoute/LoginAccount/LoginAccount.component';

import './LoginAccount.geely.style';

/** @namespace Geely/Route/LoginAccount/Component */
export class LoginAccountComponent extends JaidahLoginAccountComponent {}

export default withRouter(LoginAccountComponent);
