import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProgressBar from './ProgressBar.component';

/** @namespace Geely/Component/ProgressBar/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Geely/Component/ProgressBar/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Geely/Component/ProgressBar/Container */
export class ProgressBarContainer extends PureComponent {
    static propTypes = {
        numberOfSteps: PropTypes.number,
        currentStep: PropTypes.number,
        label: PropTypes.string,
        isMobile: PropTypes.bool.isRequired
    };

    static defaultProps = {
        numberOfSteps: 4,
        currentStep: 1,
        label: ''
    };

    state= {
        breadcrumbItems: []
    };

    componentDidMount() {
        const { currentStep, numberOfSteps } = this.props;

        this.createBreadcrumbs(currentStep, numberOfSteps);
    }

    componentDidUpdate(prevProps) {
        const { currentStep, numberOfSteps } = this.props;
        const { currentStep: pCurrentStep } = prevProps;

        if (currentStep !== pCurrentStep) {
            this.createBreadcrumbs(currentStep, numberOfSteps);
        }
    }

    createBreadcrumbs(currentStep, numberOfSteps) {
        const breadcrumbItems = Array.from(Array(numberOfSteps).keys(), (_el, i) => (
            <div
            // eslint-disable-next-line react/no-array-index-key
              key={ i }
              block="ProgressBar"
              elem="BreadcrumbItem"
              mods={ { isActive: i + 1 <= currentStep } }
            />
        ));

        this.setState({ breadcrumbItems });
    }

    containerProps = () => {
        const {
            numberOfSteps,
            currentStep,
            label,
            isMobile
        } = this.props;
        const { breadcrumbItems } = this.state;

        return {
            numberOfSteps,
            currentStep,
            label,
            isMobile,
            breadcrumbItems
        };
    };

    render() {
        return (
            <ProgressBar
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBarContainer);
