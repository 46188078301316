import { CarouselScrollItem as SourceCarouselScrollItem }
from 'SourceComponent/CarouselScrollItem/CarouselScrollItem.component';

import './CarouselScrollItem.geely.style';

/** @namespace Geely/Component/CarouselScrollItem/Component */
export class CarouselScrollItemComponent extends SourceCarouselScrollItem {
    renderSlideNumber() {
        const { isFirstOrLast, slideCount, isActive } = this.props;

        if (!isFirstOrLast || slideCount === 0) {
            return null;
        }

        return (
            <span block="CarouselScrollItem" elem="SlideNumber" mods={ { isActive } }>
                { `+${slideCount}` }
            </span>
        );
    }

    render() {
        const {
            isActive,
            itemRef,
            onClick,
            children
        } = this.props;

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="CarouselScrollItem"
              mods={ { isActive } }
              ref={ itemRef }
              onClick={ onClick }
              onKeyDown={ onClick }
            >
                { this.renderSlideNumber() }
                { children }
            </div>
        );
    }
}

export default CarouselScrollItemComponent;
