import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const StoreFinder = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "storeFinder" */ '../route/StoreFinder'));
export const StoreDetails = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "StoreDetails" */ '../route/StoreDetails'));

export class RouterPlugin {
    SWITCH_ITEMS_TYPE = (originalMember) => [
        ...originalMember,
        {
            component: <Route path="/stores" exact component={ StoreFinder } />,
            position: 90
        },
        {
            component: <Route path="/stores/:identifier" component={ StoreDetails } />,
            position: 89
        }
    ];
}

const {
    SWITCH_ITEMS_TYPE
} = new RouterPlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};

export default config;
