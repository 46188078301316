import RemoveOptionButton from 'Component/RemoveOptionButton';
import {
    RelatedProductTotalsComponent as JaidahRelatedProductTotalsComponent
} from 'JaidahComponent/RelatedProductTotals/RelatedProductTotals.component';
import {
    ACCESSORIES,
    SERVICE_PACKAGES
} from 'Store/LinkedProducts/LinkedProducts.dispatcher';
import { INSURANCE } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { formatPrice } from 'Util/Price';

import './RelatedProductTotals.geely.style';

/** @namespace Geely/Component/RelatedProductTotals/Component */
export class RelatedProductTotalsComponent extends JaidahRelatedProductTotalsComponent {
    totalItemsTypes = [SERVICE_PACKAGES, ACCESSORIES, INSURANCE];

    totalItemsTitlesMap = {
        [SERVICE_PACKAGES]: __('Service Package Price'),
        [ACCESSORIES]: __('Accessories Price'),
        [INSURANCE]: __('Insurance Price')
    };

    renderTotalItem(index, name) {
        return (
            <div
              key={ index }
              block="RelatedProductTotals"
              elem="Item"
            >
                <div
                  block="RelatedProductTotals"
                  elem="Name"
                >
                    <span block="ItemName">{ `${name}:` }</span>
                </div>
            </div>
        );
    }

    renderItemCount() {
        const {
            productType,
            categorizedItems: {
                [productType]: items = {}
            },
            insurance: { product },
            isInsurance
        } = this.props;

        const { length: itemsLength } = Object.keys(items);
        const insuranceLength = product.sku ? 1 : 0;
        const insuranceText = __('Insurance not selected');
        const lengthText = itemsLength === 1 ? __('item is selected') : __('items are selected');
        const resultingText = isInsurance ? insuranceText : `${itemsLength} ${lengthText}`;

        if (isInsurance && insuranceLength) {
            return null;
        }

        return (
            <div
              block="RelatedProductTotals"
              elem="ItemsCount"
            >
                <div block="Count">
                    { resultingText }
                </div>
            </div>
        );
    }

    renderItem(sku, name, _price, rule) {
        const { removeOption } = this.props;
        return (
            <div
              key={ sku }
              block="RelatedProductTotals"
              elem="Item"
            >
                <div
                  block="RelatedProductTotals"
                  elem="Name"
                >
                    <span block="ItemName">{ name }</span>
                </div>
                <div
                  block="RelatedProductTotals"
                  elem="Price"
                >
                    <RemoveOptionButton handler={ removeOption } handlerParams={ { sku, rule } } />
                </div>
            </div>
        );
    }

    renderClearAll() {
        return null;
    }

    renderTotals() {
        const {
            total,
            isCompact,
            vehiclePrice: {
                currency,
                value
            }
        } = this.props;

        if (isCompact && total < 0) {
            return null;
        }

        return (
            <div block="RelatedProductTotals" elem="TotalsWrapper">
                <table block="RelatedProductTotals" elem="Totals">
                    <tbody>
                        <tr block="RelatedProductTotals" elem="VehiclePrice">
                            <td>{ __('Vehicle Price') }</td>
                            <td block="RelatedProductTotals" elem="TotalsPrice">{ formatPrice(value, currency) }</td>
                        </tr>
                        { this.renderTotalItems() }
                    </tbody>
                </table>
            </div>
        );
    }

    renderTotalItems() {
        const {
            productType,
            categorizedItemsTotals,
            vehiclePrice: { currency },
            insurance: { value }
        } = this.props;

        return this.totalItemsTypes.map((totalType) => {
            const total = totalType === INSURANCE ? value : categorizedItemsTotals[totalType];

            if (!total && totalType !== productType) {
                return null;
            }

            return (
                <tr
                  block="RelatedProductTotals"
                  elem="UpgradesPrice"
                  mods={ { isActive: productType === totalType } }
                >
                    <td>{ this.totalItemsTitlesMap[totalType] }</td>
                    <td block="RelatedProductTotals" elem="TotalsPrice">
                        { `+ ${formatPrice(total || 0, currency)}` }
                    </td>
                </tr>
            );
        });
    }

    renderSelectedItems(isMobile = false) {
        const {
            productType,
            categorizedItems: {
                [productType]: items
            },
            isCompact,
            isInsurance,
            insurance: { product }
        } = this.props;

        if (isInsurance && product.sku) {
            return null;
        }

        if (!items || items.length === 0) {
            return null;
        }

        return (
            <div
              block="RelatedProductTotals"
              elem="Items"
              mods={ { isCompact: isCompact && !isMobile } }
            >
                { this.renderRuleItems(productType) }
            </div>
        );
    }

    renderMobileSelectedItems() {
        const {
            isInsurance,
            insurance: { product }
        } = this.props;

        if (isInsurance && product.sku) {
            return (
                <div
                  block="RelatedProductTotals"
                  elem="ItemsCount"
                >
                    <div block="Count">
                        { __('Insurance added') }
                    </div>
                </div>
            );
        }

        return this.renderSelectedItems(true);
    }
}

export default RelatedProductTotalsComponent;
