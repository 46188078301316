/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional, @scandipwa/scandipwa-guidelines/only-render-in-component, @scandipwa/scandipwa-guidelines/jsx-no-conditional, react/jsx-no-bind, max-lines */

import CompareTrims from 'Component/CompareTrims';
import EstimatedDelivery from 'Component/EstimatedDelivery';
import {
    ATTRIBUTES_CODE_MAP,
    COLOR_STEP,
    ENGINE_STEP, INSURANCE_STEP,
    INTERIOR_MEDIA_TYPE,
    OPTIONS_STEP,
    STEPS_MAP,
    STEPS_ORDER,
    TRIM_STEP
} from 'Component/ProductActions/ProductActions.config';
import ProductConfigurableAttributes
from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.component';
import ProductGallery from 'Component/ProductGallery';
import ProductStepsTab from 'Component/ProductStepsTab';
import RelatedProductTotals from 'Component/RelatedProductTotals';
import Swatch from 'Component/Swatch';
import Tabs from 'Component/Tabs';
import { history } from 'Util/History';
import { formatPrice } from 'Util/Price';
import {
    filterProductAccordingToMediaType,
    getMinimumConfigurableAttrPrice,
    hasMediaWithType,
    removeColorCode
} from 'Util/Product';
import { appendWithStoreCode } from 'Util/Url';

import './ProductSteps.style';

/** @namespace Scandipwa/Component/ProductSteps/Component */
export class ProductStepsComponent extends ProductConfigurableAttributes {
    stepsRenderMap = {
        [TRIM_STEP]: this.renderTrimTab.bind(this),
        [ENGINE_STEP]: this.renderEngineTab.bind(this),
        [COLOR_STEP]: this.renderColorTab.bind(this),
        [OPTIONS_STEP]: this.renderOptionsTab.bind(this),
        [INSURANCE_STEP]: this.renderInsuranceTab.bind(this)
    };

    /*
    * JAID-110 - Renders tabs accordingly each step
    */
    renderTrimTab(attributesArray) {
        const { attr } = attributesArray[0];
        return this.renderTrimType(attr);
    }

    renderEngineTab(attributesArray) {
        const { attr } = attributesArray[0];
        return this.renderSelectType(attr, true);
    }

    renderColorTab(attributesArray) {
        const {
            isMobile,
            activeProduct: {
                attributes: {
                    expected_receipt_date: {
                        attribute_value: date = ''
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!attributesArray || attributesArray.length === 0) {
            return null;
        }

        const tabs = attributesArray
            .map((attribute) => ({
                id: attribute.code ? attribute.code : '',
                name: attribute.label,
                render: this.renderTab.bind(this, attribute)
            }));

        if (isMobile) {
            return (
                <div block="ProductStepsTab" elem="ColorsTab">
                    <Tabs tabs={ tabs } />
                </div>
            );
        }

        return (
            <>
                <div block="ProductStepsTab" elem="SelectWrapper">
                    { attributesArray.map((attribute) => this.renderSwatchInLeftMenu(attribute)) }
                </div>
                <div block="ProductStepsTab" elem="SelectWrapper">
                    <EstimatedDelivery date={ date } />
                </div>
            </>
        );
    }

    renderOptionsTab() {
        const { isMobile } = this.props;
        return <RelatedProductTotals key="0" isInsurance={ false } isMobile={ isMobile } />;
    }

    renderInsuranceTab() {
        const { isMobile } = this.props;
        return <RelatedProductTotals key="1" isInsurance isMobile={ isMobile } />;
    }

    /*
    * JAID-111 - Renders Tab according to the mediaType chosen
    */
    renderTab(attribute) {
        const {
            activeProduct: product,
            areDetailsLoaded,
            isWithEmptySwitcher,
            showLoader,
            activeProduct: {
                attributes: {
                    expected_receipt_date: {
                        attribute_value: date = ''
                    } = {}
                } = {},
                media_gallery_entries = []
            } = {}
        } = this.props;
        const { code } = attribute;
        const { mediaType } = ATTRIBUTES_CODE_MAP[code];

        const isWithInteriorMedia = hasMediaWithType(media_gallery_entries, INTERIOR_MEDIA_TYPE);
        const isInterior = mediaType === INTERIOR_MEDIA_TYPE && isWithInteriorMedia;

        // Filter media array in product object according to the mediaType
        const filteredProduct = filterProductAccordingToMediaType(product, mediaType);

        return (
            <>
            { this.renderSwatchInLeftMenu(attribute) }
            <div block="ProductStepsTab" elem="Wrapper" mods={ { isInterior } }>
                <ProductGallery
                  product={ filteredProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                  isWithEmptySwitcher={ isWithEmptySwitcher }
                  showLoader={ showLoader }
                  isInterior={ isInterior }
                />
                <div block="InformationalText">
                    { __('The product you see may differ from the actual image') }
                </div>
                <EstimatedDelivery date={ date } />
            </div>
            </>
        );
    }

    /*
    * JAID-110 - Renders Swatch with name and value for color select
    */
    renderSwatchInLeftMenu(attribute) {
        const { label = '', attr = {}, code } = attribute ?? {};
        const {
            parameters: {
                [code]: selectedParameter = ''
            } = {},
            configurable_options: {
                [code]: {
                    attribute_options = {}
                } = {}
            },
            isMobile
        } = this.props;
        const {
            [selectedParameter]: {
                label: selectedValue = ''
            } = {}
        } = attribute_options;

        if (!selectedValue) {
            return null;
        }

        return (
            <Swatch
              key={ label }
              label={ !isMobile ? label : '' }
              selectedValue={ removeColorCode(selectedValue) }
            >
                { this.renderSwatch(attr) }
            </Swatch>
        );
    }

    /*
    * JAID-136 - Renders button for select type configurable attributes with optional price
    */
    renderSelectButton(option = {}, attribute_code, hasPrice = false, isStarting = false) {
        const {
            label = '',
            value = ''
        } = option;

        const { updateConfigurableVariant, parameters = {} } = this.props;

        const attributeValuePrice = hasPrice ? this.renderAttributeValuePrice(attribute_code, value, isStarting)
            : false;

        if (attributeValuePrice) {
            return (
                <button
                  key={ value || attribute_code }
                  onClick={ () => updateConfigurableVariant(attribute_code, value) }
                  block="ProductStepsTab"
                  elem="SelectItem"
                  mods={ {
                      active: parameters?.[attribute_code] === value
                  } }
                >
                { label }
                { hasPrice && attributeValuePrice }
                </button>
            );
        }

        return null;
    }

    /*
    * JAID-136 - Renders select type configurable attributes
    */
    renderSelectType(attribute, hasPrice = false, isStarting, showOutOfStock = false) {
        const {
            attribute_options = {},
            attribute_code = ''
        } = attribute || {};

        if (showOutOfStock) {
            const {
                values = []
            } = attribute || {};

            return (
                <div block="ProductStepsTab" elem="SelectWrapper">
                    { values.map(
                        ({ value_index }) => this.renderSelectButton(
                            attribute_options[value_index],
                            attribute_code,
                            hasPrice,
                            isStarting
                        )
                    ) }
                </div>
            );
        }

        const {
            attribute_values = []
        } = attribute || {};

        return (
            <div block="ProductStepsTab" elem="SelectWrapper">
                { attribute_values.map(
                    (option) => this.renderSelectButton(
                        attribute_options[option],
                        attribute_code,
                        hasPrice,
                        isStarting
                    )
                ) }
            </div>
        );
    }

    /*
    * JAID-136 - Renders configurable attribute price based on the selected parameters
    */
    renderAttributeValuePrice(attribute_code, attribute_value, isStarting) {
        const { variants, parameters, currentStepNumber } = this.props;
        const { value, currency } = getMinimumConfigurableAttrPrice(
            attribute_code,
            attribute_value,
            variants,
            parameters,
            currentStepNumber
        );

        if (!value) {
            return null;
        }

        const formattedPrice = formatPrice(value, currency);
        const content = isStarting
            ? __('Starting from %s', formattedPrice)
            : formattedPrice;

        return (
            <div block="ConfigurableSelectPrice">
                { content }
            </div>
        );
    }

    /*
    * JAID-136 - Renders compare trims button for trim attribute
    */
    renderCompareTrimsButton() {
        const { toggleCompareTrims } = this.props;

        return (
            <div block="ProductSteps" elem="CompareTrimsWrapper">
                <button
                  block="ProductSteps"
                  elem="CompareButton"
                  onClick={ () => toggleCompareTrims(true) }
                >
                    <span block="ProductSteps" elem="CompareIcon" />
                    <span block="ProductSteps" elem="CompareButtonText">
                        { __('Compare Trims') }
                    </span>
                </button>
            </div>
        );
    }

    /*
    * JAID-136 - Renders trim attribute type
    */
    renderTrimType(attribute) {
        const { activeProduct: { variants = [] } } = this.props;
        const {
            attribute_code = '',
            attribute_values = []
        } = attribute || {};

        const enabledAttributeValues = variants.map((variant) => variant?.attributes[attribute_code]?.attribute_value);

        const filteredAttributeValues = attribute_values.filter(
            (attrVal) => enabledAttributeValues.includes(attrVal)
        );

        const filteredAttibute = {
            ...attribute,
            attribute_values: filteredAttributeValues,
            values: filteredAttributeValues.map((val) => ({ value_index: val }))
        };

        return (
            <>
                { this.renderCompareTrimsButton() }
                { this.renderSelectType(filteredAttibute, true, true, true) }
            </>
        );
    }

    /**
    * Added to render Product step tabs
    * JAID-112 - Overridden to add popup to header
    */
    renderTabs() {
        const {
            configurable_options = {},
            parameters = {},
            changeStep,
            currentStepNumber,
            activeProduct,
            isMobile,
            inStock
        } = this.props;

        if (Object.keys(configurable_options).length === 0) {
            return null;
        }

        return STEPS_ORDER.map((step) => {
            if (!STEPS_MAP[step].isLoadedInLeftMenu) {
                return null;
            }

            const { attribute_codes, number: stepNumber, label } = STEPS_MAP[step];

            const attributesArray = attribute_codes.map(({ code, label }) => {
                const { [code]: attr } = configurable_options;

                return {
                    code,
                    attr,
                    label: typeof label === 'function' ? label() : label
                };
            });

            const selectedValueArray = attributesArray.map((attribute) => (
                attribute.attr?.attribute_values?.includes(parameters?.[attribute.code])
                    ? {
                        value: attribute.attr.attribute_options?.[parameters?.[attribute.code]]?.label,
                        label: attribute.label,
                        code: attribute.code
                    } : { value: '', label: '', code: '' }));

            const isBehind = stepNumber < currentStepNumber;
            const isNextStep = currentStepNumber - stepNumber === -1;

            const desktopTabToggleHandler = () => (
                isBehind || (isNextStep && inStock) ? changeStep(stepNumber) : null
            );

            const mobileBackHandler = () => {
                if (currentStepNumber > 1) {
                    changeStep(currentStepNumber - 1);
                } else {
                    history.push(appendWithStoreCode('/new-cars'));
                }
            };

            const hasPopUp = !!STEPS_MAP[step].popUp;
            const { [step]: renderStep = () => null } = this.stepsRenderMap;
            const stepsLabel = typeof label === 'function' ? label() : label;

            return (
                <ProductStepsTab
                  activeProduct={ activeProduct }
                  key={ stepNumber }
                  attribute_label={ `${stepNumber}. ${stepsLabel}` }
                  active={ stepNumber === currentStepNumber }
                  selectedValueArray={ selectedValueArray }
                  handleOnClick={ desktopTabToggleHandler }
                  handleMobileBack={ mobileBackHandler }
                  isBehind={ isBehind }
                  isNextStep={ isNextStep }
                  isMobile={ isMobile }
                  isPopUpOnMobile={ hasPopUp }
                  inStock={ inStock }
                  popUpAttributes={ hasPopUp ? STEPS_MAP[step].popUp : {} }
                  stepCode={ step }
                >
                    { renderStep(attributesArray) }
                </ProductStepsTab>
            );
        });
    }

    renderCompareTrims() {
        const {
            activeProduct,
            isCompareTrimsVisible,
            toggleCompareTrims
        } = this.props;

        if (!isCompareTrimsVisible) {
            return null;
        }

        return (
            <CompareTrims
              product={ activeProduct }
              onBack={ toggleCompareTrims }
            />
        );
    }

    /**
     * Overridden to add tab functionality
     */
    render() {
        const { isReady, mix } = this.props;

        return (
            <div
              block="ProductConfigurableAttributes"
              elem="Wrapper"
              mods={ { isLoading: !isReady } }
              mix={ mix }
            >
                { isReady ? this.renderTabs() : this.renderPlaceholders() }
                { this.renderCompareTrims() }
            </div>
        );
    }
}

export default ProductStepsComponent;
