/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import SourceNewsletterSubscription from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

import newsletterSubscriptionForm from './NewsletterForm.form';

/**
 * Newsletter Subscription form
 * @class NewsletterSubscription
 * @namespace Geely/Component/NewsletterSubscription/Component */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscription {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get fieldMap() {
        const { isTOSAccepted, setTOS } = this.props;

        return newsletterSubscriptionForm(isTOSAccepted, setTOS);
    }

    renderActions() {
        return (
            <button
              type={ FIELD_TYPE.submit }
              block="Button"
              mods={ { isHollow: true } }
              aria-label={ __('Submit') }
            />
        );
    }

    render() {
        const { isTOSAccepted, setTOS } = this.props;

        return (
            <div block="NewsletterSubscription">
                <Form { ...this.getFormProps() } block="FieldForm">
                    { this.renderFormBody() }
                </Form>
                <Field
                  type={ FIELD_TYPE.checkbox }
                  attr={ {
                      id: 'termsAndConditionsNewsletter',
                      name: 'termsAndConditionsNewsletter',
                      value: 'termsAndConditionsNewsletter',
                      checked: isTOSAccepted
                  } }
                  label={ __('I agree to terms and conditions') }
                  events={ {
                      onChange: setTOS
                  } }
                  addRequiredTag
                  mix={ { block: 'NewsletterSubscription', elem: 'TermsAndConditions-Checkbox' } }
                />
            </div>
        );
    }
}

export default NewsletterSubscriptionComponent;
