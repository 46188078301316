import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import { getCartId as getGuestQuoteId } from 'Util/Cart';

import { EVENT_GTM_USER_LOGIN } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const GoogleTagManagerDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/GoogleTagManager/GoogleTagManager.dispatcher'
);

export const aroundMapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        signIn: async (skipCartUpdate) => {
            dispatch(updateCustomerSignInStatus(true));
            MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch));
            WishlistDispatcher.then(({ default: dispatcher }) => dispatcher._syncWishlistWithBE(dispatch));
            dispatch(event(EVENT_GTM_USER_LOGIN));
            const cartDispatcher = (await CartDispatcher).default;
            const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);
            setGuestQuoteId(customerCartToken);

            if (!skipCartUpdate) {
                CartDispatcher.then(({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch));
            }
        }
    };
};

export default {
    'Component/Router/Container/mapDispatchToProps': {
        function: [aroundMapDispatchToProps]
    }
};
