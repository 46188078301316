import {
    LINKED_PRODUCTS,
    LinkedProductsDispatcher as JaidahLinkedProductsDispatcher
} from 'JaidahStore/LinkedProducts/LinkedProducts.dispatcher';
import { updateLinkedProducts } from 'Store/LinkedProducts/LinkedProducts.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProduct } from 'Util/Product';

export const SERVICE_PACKAGES = 'geely_service';
export const ACCESSORIES = 'geely_accessories';
export const HYUNDAI = 'hyundai';

export { LINKED_PRODUCTS };

/** @namespace Geely/Store/LinkedProducts/Dispatcher */
export class LinkedProductsDispatcher extends JaidahLinkedProductsDispatcher {
    _processResponse(data, product_links) {
        const { products: { items } } = data;

        const indexedBySku = items.reduce((acc, item) => {
            const { sku } = item;
            acc[sku] = getIndexedProduct(item);

            return acc;
        }, {});

        const linkedProducts = product_links.reduce((acc, link) => {
            const {
                linked_product_sku,
                link_type,
                rule_id,
                block_name,
                one_product_added,
                insurance_rule,
                related_products_type
            } = link;

            if (indexedBySku[linked_product_sku] && acc[link_type]) {
                if (link_type === 'automatic_related' && !insurance_rule
                    && acc[link_type].items[related_products_type]) {
                    if (!acc[link_type].items[related_products_type].items) {
                        const rule = {
                            block_name,
                            total_count: 0,
                            items: [],
                            rule_id,
                            one_product_added
                        };

                        acc[link_type].items[related_products_type] = rule;
                        acc[link_type].total_count++;
                    }

                    acc[link_type].items[related_products_type].items.push(indexedBySku[linked_product_sku]);
                    acc[link_type].items[related_products_type].total_count++;
                } else if (link_type === 'automatic_related' && insurance_rule) {
                    acc.insurance.items.push(indexedBySku[linked_product_sku]);
                    acc.insurance.total_count++;
                } else if (acc[link_type].items && acc[link_type].items.length) {
                    acc[link_type].items.push(
                        indexedBySku[linked_product_sku]
                    );

                    acc[link_type].total_count++;
                }
            }

            return acc;
        }, {
            upsell: { total_count: 0, items: [] },
            related: { total_count: 0, items: [] },
            crosssell: { total_count: 0, items: [] },
            associated: { total_count: 0, items: [] },
            automatic_related: {
                total_count: 0,
                items: {
                    [SERVICE_PACKAGES]: {},
                    [ACCESSORIES]: {},
                    [HYUNDAI]: {}
                }
            },
            insurance: { total_count: 0, items: [] }
        });

        return linkedProducts;
    }

    clearLinkedProducts(dispatch, updateCrossSell = false) {
        const linkedProducts = {
            upsell: { total_count: 0, items: [] },
            related: { total_count: 0, items: [] },
            crosssell: { total_count: 0, items: [] },
            automatic_related: {
                total_count: 3,
                items: {
                    [ACCESSORIES]: {},
                    [SERVICE_PACKAGES]: {},
                    [HYUNDAI]: {}
                }
            },
            insurance: { total_count: 0, items: [] }
        };

        BrowserDatabase.setItem(linkedProducts, LINKED_PRODUCTS);

        dispatch(updateLinkedProducts({
            ...linkedProducts,
            updateCrossSell
        }));
    }
}

export default new LinkedProductsDispatcher();
