import ContentWrapper from 'Component/ContentWrapper';
import {
    SomethingWentWrong as SourceSomethingWentWrong
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Scandipwa/Route/SomethingWentWrong/Component */
export class SomethingWentWrongComponent extends SourceSomethingWentWrong {
    // overriden to appendstorecode to homeurl
    render() {
        const { onClick } = this.props;

        const homeUrl = appendWithStoreCode('/');

        return (
            <main block="SomethingWentWrong">
                <ContentWrapper label="Something went wrong on the page.">
                    <h1 block="SomethingWentWrong" elem="Heading">{ __('Ooops!') }</h1>
                    <h2 block="SomethingWentWrong" elem="SubHeading">{ __('Something went wrong!') }</h2>
                    { /* eslint-disable-next-line react/forbid-elements */ }
                    <a
                      href={ homeUrl }
                      block="SomethingWentWrong"
                      elem="Button"
                      mix={ { block: 'Button' } }
                      onClick={ onClick }
                    >
                        { __('Back to homepage') }
                    </a>
                    { this.renderErrorDetails() }
                </ContentWrapper>
            </main>
        );
    }
}

export default SomethingWentWrongComponent;
