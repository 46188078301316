/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import CloseIcon from 'Component/CloseIcon';
import {
    ToolTipPopupComponent as JaidahToolTipPopupComponent
} from 'JaidahComponent/ToolTipPopup/ToolTipPopup.component';
import { toggleScroll } from 'Util/Browser';

import './ToolTipPopup.geely.style';

/** @namespace Geely/Component/ToolTipPopup/Component */
export class ToolTipPopupComponent extends JaidahToolTipPopupComponent {
    freezeScroll() {
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);
        document.body.style.marginTop = `${-this.YoffsetWhenScrollDisabled}px`;
    }

    unfreezeScroll() {
        toggleScroll(true);
        document.body.style.marginTop = 0;
        window.scrollTo(0, this.YoffsetWhenScrollDisabled);
    }

    // overridden to make unfreeze an option
    onHide() {
        const { onHide, isMobile, skipUnfreeze } = this.props;

        if (isMobile) {
            if (!skipUnfreeze) {
                this.unfreezeScroll();
            }

            window.removeEventListener('popstate', this.hidePopUp);
        }

        onHide();
    }

    /**
     * Overridden to change onClick handler
     */
    renderCloseButton() {
        const { isMobile } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close') }
              onClick={ this.handleClickOutside }
            >
                <CloseIcon />
            </button>
        );
    }
}

export default ToolTipPopupComponent;
