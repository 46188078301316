import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    RouterContainer as JaidahRouterContainer
} from 'JaidahComponent/Router/Router.container';

export * from 'JaidahComponent/Router/Router.container';

/** @namespace Geely/Component/Router/Container */
export class RouterContainer extends JaidahRouterContainer {
    containerProps() {
        const { device: { isMobile } } = this.props;

        return {
            ...super.containerProps(),
            isMobile
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
