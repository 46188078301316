import { CarouselScrollItemContainer as SourceCarouselScrollItem }
from 'SourceComponent/CarouselScrollItem/CarouselScrollItem.container';

/** @namespace Geely/Component/CarouselScrollItem/Container */
export class CarouselScrollItemContainer extends SourceCarouselScrollItem {
    containerProps() {
        const {
            isFirstOrLast,
            slideCount
        } = this.props;

        return {
            ...super.containerProps(),
            isFirstOrLast,
            slideCount
        };
    }
}

export default CarouselScrollItemContainer;
