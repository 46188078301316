import {
    LoaderComponent as JaidahLoaderComponent
} from 'JaidahComponent/Loader/Loader.component';
import GeelyLogo from 'Style/logos/geely_logo.png';

import './Loader.geely.style';

/** @namespace Geely/Component/Loader/Component */
export class LoaderComponent extends JaidahLoaderComponent {
    renderSkylineLoader() {
        return (
            <div block="SkylineLoader">
                <img src={ GeelyLogo } alt={ __('Loading...') } />
            </div>
        );
    }
}

export default LoaderComponent;
