import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Link from 'Component/Link';
import {
    MyAccountCreateAccountComponent as JaidahMyAccountCreateAccountComponent
} from 'JaidahComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/** @namespace Geely/Component/MyAccountCreateAccount/Component */
export class MyAccountCreateAccountComponent extends JaidahMyAccountCreateAccountComponent {
    // overriden to change label text
    renderSubscribeToNewsletter() {
        const { newsletterActive } = this.props;

        if (!newsletterActive) {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              label={ __('I wish to receive communication on Geely products & services.') }
              attr={ {
                  id: 'is_subscribed',
                  name: 'is_subscribed'
              } }
            />
        );
    }

    // overriden to change placeholder to 'e.g. abc@geely.com'
    renderEmail() {
        const { location: { state: { email = '' } = {} } } = history;

        return (
            <div block="Field" elem="Email">
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('ID (Email)') }
                  subLabel={ __('※ Please enter a valid email address. To activate your account, you'
                   + ' must complete the email verification process.') }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      defaultValue: email,
                      placeholder: __('e.g. abc@geely.com'),
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  addRequiredTag
                />
            </div>
        );
    }

    // overriden to make link open in a new tab
    renderTermsCheckbox() {
        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              label={ __('I accept the Terms & Conditions.') }
              labelLink={ this.renderViewDetailLink('/terms-and-conditions', true) }
              attr={ {
                  id: 'terms',
                  name: 'terms',
                  defaultChecked: false
              } }
              validationRule={ {
                  isRequired: true,
                  customErrorMessages: {
                      onRequirementFail: __('This checkbox is required')
                  }
              } }
              addRequiredTag
            />
        );
    }

    // overriden to make link open in a new tab
    renderPrivacyCheckbox() {
        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              label={ __('I accept the Privacy Policy.') }
              labelLink={ this.renderViewDetailLink('/privacy-policy-cookie', true) }
              attr={ {
                  id: 'privacy',
                  name: 'privacy',
                  defaultChecked: false
              } }
              validationRule={ {
                  isRequired: true,
                  customErrorMessages: {
                      onRequirementFail: __('This checkbox is required')
                  }
              } }
              addRequiredTag
            />
        );
    }

    // overriden to add opening in a new tab option
    renderViewDetailLink(path, newTab = false) {
        if (newTab) {
            return (
                <Link to={ appendWithStoreCode(path) } target="_blank">
                    { __('View Detail') }
                </Link>
            );
        }

        return (
            <Link to={ appendWithStoreCode(path) }>
                { __('View Detail') }
            </Link>
        );
    }
}

export default MyAccountCreateAccountComponent;
