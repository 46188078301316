import { connect } from 'react-redux';

import { MY_VEHICLES_URL } from 'Component/Header/Header.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    OrderReservationContainer as SourceOrderReservationContainer
}
from 'JaidahRoute/OrderReservation/OrderReservation.container';
import ReservationReducer from 'Store/Reservation/Reservation.reducer';
import { withReducers } from 'Util/DynamicReducer';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Geely/Route/OrderReservation/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    breadcrumbs: state.BreadcrumbsReducer.breadcrumbs
});

/** @namespace Geely/Route/OrderReservation/Container */
export class OrderReservationContainer extends SourceOrderReservationContainer {
    updateOrderBreadcrumbs() {
        const { updateBreadcrumbs, toggleBreadcrumbs } = this.props;
        toggleBreadcrumbs(true);

        updateBreadcrumbs([{ name: __('Order'), url: '', updateCheck: true }, {
            name: __('Back to My Vehicles'),
            url: appendWithStoreCode(MY_VEHICLES_URL)
        }]);
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        const { breadcrumbs } = this.props;

        if (!breadcrumbs[0].updateCheck) {
            this.updateOrderBreadcrumbs();
        }
    }
}

export default withReducers({
    ReservationReducer
})(connect(mapStateToProps, mapDispatchToProps)(OrderReservationContainer));
