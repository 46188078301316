import ChevronIcon from 'Component/ChevronIcon';
import {
    CompareTrimsComponent as JaidahCompareTrimsComponent
} from 'JaidahComponent/CompareTrims/CompareTrims.component';

import './CompareTrims.geely.style';

export * from 'JaidahComponent/CompareTrims/CompareTrims.component';

/** @namespace Geely/Component/CompareTrims/Component */
export class CompareTrimsComponent extends JaidahCompareTrimsComponent {
    // overridden to reverse the buttons direction on arabic store view
    renderScrollButton(direction, elem) {
        const {
            onScrollControl,
            scrollVisibility,
            reverseDirection
        } = this.props;

        const isRtl = document.dir === 'rtl';

        const switchedDirection = isRtl ? reverseDirection(direction) : direction;

        return (
            <button
              mix={ [
                  { block: 'CompareTrims', elem },
                  {
                      block: 'CompareTrims',
                      elem: 'ScrollButton',
                      mods: { isDisabled: !scrollVisibility[direction] }
                  }
              ] }
                  // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => onScrollControl(switchedDirection) }
              aria-label={ elem }
            >
                <ChevronIcon direction={ direction } />
            </button>
        );
    }
}

export default CompareTrimsComponent;
