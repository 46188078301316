import {
    ProductListQuery as JaidahProductListQuery
} from 'JaidahQuery/ProductList.query';
import { isMobile } from 'Util/Mobile';

/** @namespace Geely/Query/ProductList/Query */
export class ProductListQuery extends JaidahProductListQuery {
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const { isPlp = false } = this.options;

        const fields = super._getProductInterfaceFields(isVariant, isForLinkedProducts, isForWishlist);

        // Allows to display second image on hover
        if (!isMobile?.any() && isPlp && !isVariant) {
            fields.push(
                this._getMediaGalleryField()
            );
        }

        return fields;
    }

    _getARPFields() {
        const arpFields = super._getARPFields();

        arpFields.push('related_products_type');

        return arpFields;
    }
}

export default new ProductListQuery();
