import { EstimatedDeliveryComponent as SourceEstimatedDelivery }
from 'JaidahComponent/EstimatedDelivery/EstimatedDelivery.component';

import './EstimatedDelivery.geely.style';

/** @namespace Geely/Component/EstimatedDelivery/Component */
export class EstimatedDeliveryComponent extends SourceEstimatedDelivery {
    /**
     * Overridden to change the text in EstimatedDelivery-Header block
     */
    render() {
        const { date } = this.props;

        if (!date) {
            return null;
        }

        return (
            <div block="EstimatedDelivery">
                <div block="EstimatedDelivery" elem="Image" />
                <div block="EstimatedDelivery" elem="Content">
                    <span block="EstimatedDelivery" elem="Header">
                        { __('The estimated delivery date is') }
                    </span>
                    <span block="EstimatedDelivery" elem="Value">
                        { date }
                    </span>
                </div>
            </div>
        );
    }
}

export default EstimatedDeliveryComponent;
