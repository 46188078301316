// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CompareIcon.style';

/** @namespace Geely/Component/CompareIcon/Component */
export class CompareIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="CompareIcon"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M5 7.5H20.5" strokeWidth="1.5" />
                <path d="M20 16.5L4.5 16.5" strokeWidth="1.5" />
                <path d="M16.5 3L21 7.5L16.5 12" strokeWidth="1.5" />
                <path d="M8.5 21L4 16.5L8.5 12" strokeWidth="1.5" />
            </svg>
        );
    }
}

export default CompareIconComponent;
