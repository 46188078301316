export const getFilterOptionsForPrice = (args) => {
    const [values, options] = args;
    const [fromValue, toValue] = values[0].split('_');
    const option = options[options.length - 1];

    const { label: initialLabel } = option;

    const [from, to] = initialLabel.split('~');
    const rangeFrom = fromValue === '*' ? from : fromValue;
    const rangeEnd = toValue === '*' ? to : toValue;

    const formattedFrom = new Intl.NumberFormat().format(rangeFrom);
    const formattedTo = new Intl.NumberFormat().format(rangeEnd);
    const label = `${formattedFrom} - ${formattedTo}`;

    return [{ value_string: `${rangeFrom}_${rangeEnd}`, label }];
};

export default {
    'Component/ResetAttributes/Container': {
        'member-function': {
            getFilterOptionsForPrice: {
                implementation: getFilterOptionsForPrice,
                position: 110
            }
        }
    }
};
