import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    TT_BOTTOM_L, TT_BOTTOM_R, TT_LEFT, TT_RIGHT, TT_TOP_L, TT_TOP_R
} from 'Component/ToolTipWrapper/ToolTipWrapper.config';

import ProductMonthlyPrice from './ProductMonthlyPrice.component';

/** @namespace Scandipwa/Component/ProductMonthlyPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isEnabled: state.ConfigReducer.monthly_payment_display_enable,
    downpayment: state.ConfigReducer.monthly_payment_display_downpayment,
    interest: state.ConfigReducer.monthly_payment_display_interest,
    installments: state.ConfigReducer.monthly_payment_display_instalments,
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/ProductMonthlyPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandipwa/Component/ProductMonthlyPrice/Container */
export class ProductMonthlyPriceContainer extends PureComponent {
    static propTypes = {
        isEnabled: PropTypes.bool.isRequired,
        downpayment: PropTypes.string.isRequired,
        interest: PropTypes.string.isRequired,
        installments: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        block: PropTypes.string,
        elem: PropTypes.string,
        position: PropTypes.oneOf([TT_BOTTOM_L, TT_LEFT, TT_RIGHT, TT_TOP_L, TT_BOTTOM_R, TT_TOP_R]),
        isClickable: PropTypes.bool,
        id: PropTypes.string.isRequired,
        isInfoButton: PropTypes.bool,
        isMobile: PropTypes.bool.isRequired,
        popupOpenDirection: PropTypes.string
    };

    static defaultProps = {
        block: 'ProductMonthlyPrice',
        elem: 'ProductMonthlyPriceContainer',
        position: TT_TOP_L,
        isClickable: false,
        isInfoButton: true,
        popupOpenDirection: 'top'
    };

    containerProps() {
        const {
            isEnabled,
            downpayment,
            interest,
            installments,
            price,
            currency,
            block,
            elem,
            position,
            isClickable,
            id,
            isInfoButton,
            isMobile,
            popupOpenDirection
        } = this.props;

        return {
            isEnabled,
            downpayment,
            interest,
            installments,
            price,
            currency,
            block,
            elem,
            position,
            isClickable,
            id,
            isInfoButton,
            isMobile,
            popupOpenDirection
        };
    }

    render() {
        return (
            <ProductMonthlyPrice
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductMonthlyPriceContainer);
