/* eslint-disable no-magic-numbers */
/* eslint-disable react/prop-types */
import { PureComponent } from 'react';

import ToolTipButton from 'Component/ToolTipButton';
import { calculatePricePerMonth, formatPrice } from 'Util/Price/Price';

import './ProductMonthlyPrice.style';

/** @namespace Scandipwa/Component/ProductMonthlyPrice/Component */
export class ProductMonthlyPriceComponent extends PureComponent {
    renderPricePerMonth() {
        const {
            downpayment,
            interest,
            installments,
            price,
            currency,
            block
        } = this.props;

        const pricePerMonth = formatPrice(calculatePricePerMonth(
            price, installments, interest, downpayment
        ), currency);

        return (
            <span block={ block } elem="PriceValue">
                { `${pricePerMonth} ${__('pm')}` }
            </span>
        );
    }

    renderToolTipContent() {
        const {
            downpayment,
            installments
        } = this.props;

        const itemList = [
            {
                label: __('Price per month calculation example:'),
                label_options: 'bold'
            },
            {
                label: __('Down payment %'),
                value: `${downpayment}%`
            },
            {
                label: __('Number of installments'),
                value: `${installments } ${ __('months')}`
            }
        ];

        return (
            <div block="MonthlyPaymentTooltip" elem="Wrapper">
                { itemList.map((item) => this.renderToolTipItem(item)) }
            </div>
        );
    }

    renderToolTipItem({ label, value = '', label_options = '' }) {
        return (
            <div block="MonthlyPaymentTooltip" elem="Item">
                <div block="MonthlyPaymentTooltip" elem="Label" mix={ { block: label_options } }>
                    { label }
                </div>
                { value && (
                    <div block="MonthlyPaymentTooltip" elem="Value">
                    { value }
                    </div>
                ) }
            </div>
        );
    }

    render() {
        const {
            block,
            elem,
            isEnabled,
            id,
            isInfoButton,
            isMobile,
            popupOpenDirection
        } = this.props;

        if (!isEnabled) {
            return null;
        }

        return (
            <div block={ block } elem={ elem } mix={ { block: 'ProductMonthlyPrice' } }>
                <div
                  block="ProductMonthlyPrice"
                  elem="PriceWrapper"
                >
                    { this.renderPricePerMonth() }
                    { isInfoButton && (
                        <ToolTipButton
                          hasCloseBtn={ false }
                          isHoverable={ !isMobile }
                          tooltipContent={ this.renderToolTipContent() }
                          popupOpenDirection={ popupOpenDirection }
                          id={ id }
                        />
                    ) }
                </div>

            </div>
        );
    }
}

export default ProductMonthlyPriceComponent;
