/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import ChevronIcon from 'Component/ChevronIcon/ChevronIcon.component';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import { SliderComponent as SourceSlider } from 'JaidahComponent/Slider/Slider.component';

import './Slider.geely.style';

/** @namespace Geely/Component/Slider/Component */
export class Slider extends SourceSlider {
    renderArrows() {
        const { showArrows, activeImage, children } = this.props;
        const nextIsDisabled = activeImage + 1 === children.length;
        const prevIsDisabled = activeImage === 0;

        if (!showArrows) {
            return null;
        }

        return (
            <>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true, isDisabled: prevIsDisabled } }
                  aria-label={ __('Previous') }
                  onClick={ this.goPrev }
                >
                    <ChevronIcon isGeelyArrow direction={ LEFT } />
                </button>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true, isDisabled: nextIsDisabled } }
                  aria-label={ __('Next') }
                  onClick={ this.goNext }
                >
                    <ChevronIcon isGeelyArrow direction={ RIGHT } />
                </button>
            </>
        );
    }

    /**
     * Overridden to add +2px gap
     */
    getSlideWidth() {
        const { isVertical } = this.props;
        const { offsetWidth = 0, offsetHeight = 0 } = this.draggableRef.current || {};

        // eslint-disable-next-line no-magic-numbers
        return isVertical ? offsetHeight + 2.4 : offsetWidth + 2.4;
    }
}

export default Slider;
