import {
    COLOR_EXTERIOR, COLOR_INTERIOR, ENGINE, TRIM
} from 'Component/ProductActions/ProductActions.config';
import { PRODUCT_TYPE } from 'JaidahComponent/Product/Product.config';

export * from 'JaidahComponent/Product/Product.config';

export const OUT_OF_STOCK = 'OUT_OF_STOCK';
export const PARAM_LIST = [COLOR_EXTERIOR, COLOR_INTERIOR, ENGINE, TRIM];

export default PRODUCT_TYPE;
