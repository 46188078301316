// import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceDispatch,
    mapStateToProps as sourceMap,
    PopupContainer
} from 'Component/Popup/Popup.container';

import ToolTipPopup from './ToolTipPopup.component';

/** @namespace Scandipwa/Component/ToolTipPopup/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    ...sourceMap(_state)
});

/** @namespace Scandipwa/Component/ToolTipPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    ...sourceDispatch(_dispatch)
});

/** @namespace Scandipwa/Component/ToolTipPopup/Container */
export class ToolTipPopupContainer extends PopupContainer {
    containerProps() {
        const {
            direction = 'top',
            setActive,
            isForceVisible,
            hideTooltip
        } = this.props;

        return {
            ...super.containerProps(),
            direction,
            setActive,
            isForceVisible,
            hideTooltip
        };
    }

    render() {
        return (
            <ToolTipPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolTipPopupContainer);
